import { Injectable } from '@angular/core';
import { apiUrls } from '@appEnv/apiurls';
import { environment } from '@appEnv/environment';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class WastageqrreqaddqrbarService {

  wholesaleNewURL = environment.wholesaleNewURL;


  constructor(
    private http: HttpClient
  ) { }


  getAlllist(obj): Observable<any> {
    const url = this.wholesaleNewURL + 'customdata/getdata';
    return this.http.post(url, obj);
  }
  savedata(obj): Observable<any> {
    const url = this.wholesaleNewURL + 'orderpickup/saveorderpickup';
    return this.http.post(url, obj)
  }

  masterdata(obj): Observable<any> {
    const url = this.wholesaleNewURL + 'master/data/listmasterdata';
    return this.http.post(url, obj);
  }
  getcustomerdata(obj) {
    const url = this.wholesaleNewURL + "customdata/getdata";
    return this.http.post(url, obj)
  }
  getRecordCount(postParam): any {
    const url = this.wholesaleNewURL + 'customdata/getdata';
    return this.http.post(url, postParam).toPromise();
  }
  addWastageReq(obj): Observable<any> {
    const url = this.wholesaleNewURL + 'addwastageadditionalrequest';
    return this.http.post(url, obj)
  }
  approve(obj): Observable<any> {
    const url = this.wholesaleNewURL + 'approvalflow/process';

    return this.http.post(url, obj);
  }
  rejected(paylod): Observable<any> {
    const url = this.wholesaleNewURL + 'approvalflow/processrequest';
    return this.http.post(url, paylod);
  }

  requestForClarify(paylod): Observable<any> {
    const url = this.wholesaleNewURL + 'approvalflow/processrequest';
    return this.http.post(url, paylod);
  }
}
