export class enaPurchaseOrderAcceptanceConfig {

  public static columns1 = [
    {
      name: 'id',
      displayName: 'S.No.',
      type: 'number'
    },

    {
      name: "createdDate",
      displayName: 'enaPurchaseOrder.list.date',
      type: "date",
      dateFormat: 'dd/MM/yyyy',
    },
    {
      name: 'applicationNumber',
      displayName: 'Application Number',
      type: 'string'
    },
    {
      name: 'purchaseOrderId',
      displayName: 'Purchase Order Number',
      type: 'string'
    },
    {
      name: 'purpose',
      displayName: 'Purpose',
      type: 'string'
  },
    {
      name: 'reqBLQty',
      displayName: 'enaPurchaseOrder.list.reqQuantity',
      type: 'string'
    },
    // {
    //   name: 'saleTypeValue', //saleTypeCode',
    //   displayName: 'enaPurchaseOrder.list.saleType',
    //   type: 'string'
    // },
    // {
    //     name: 'purchaserUnitName',
    //     displayName:'enaPurchaseOrder.list.purchaserName',
    //     type: 'string'
    // },
    {
      name: 'unitName',
      displayName: 'enaPurchaseOrder.list.purchaserName',
      type: 'string'
    },

    {
      name: 'sellerUnitName',
      displayName: 'enaPurchaseOrder.list.sellerName',
      type: 'string'
    },

    {
      name: "stageDesc",
      displayName: 'language.currentlywith',
      displayFcn: (item: any) => (item.status == 'DRAFT' || item.status == 'SUBMITTED' || item.stageDesc == null || item.status == 'COMPLETED') ? '-' : item.stageDesc,
      type: 'objstring'
    },

    {
      name: 'statusDesc',
      displayName: 'enaPurchaseOrder.list.status',
      type: 'options',
      badgeColors: {
        'Draft': 'badge-primary',
        'In Progress': 'badge-secondary',
        'Approved': 'badge-success',
        'Submitted': 'badge-info',
        'Rejected': 'badge-danger',
        'Clarification': 'badge-warning',
        'Pending': 'badge-warning',
        'Accepted': 'badge-success',
        'Completed':'badge-success',
        'Expired' : 'badge-danger'
      }
    },
    // {
    //   name: 'isActive',
    //   displayName: 'master.common.status',
    //   type: 'boolean',
    //   optionVal: ['Active', 'In-Active']
    // },
  ];
  public static columns2 = [
    {
      name: 'id',
      displayName: 'S.No.',
      type: 'number'
    },

    {
      name: "createdDate",
      displayName: 'enaPurchaseOrder.list.date',
      type: "date",
      dateFormat: 'dd/MM/yyyy',
    },
    {
      name: 'applicationNumber',
      displayName: 'Application Number',
      type: 'string'
    },
    {
      name: 'purchaseOrderId',
      displayName: 'Purchase Order Number',
      type: 'string'
    },
    {
      name: 'purpose',
      displayName: 'Purpose',
      type: 'string'
  },
    {
      name: 'reqBLQty',
      displayName: 'enaPurchaseOrder.list.reqQuantity',
      type: 'string'
    },
    {
      name: 'unitName',
      displayName: 'enaPurchaseOrder.list.purchaserName',
      type: 'string'
    },

    {
      name: 'sellerUnitName',
      displayName: 'enaPurchaseOrder.list.sellerName',
      type: 'string'
    },

    {
      name: "stageDesc",
      displayName: 'language.currentlywith',
      displayFcn: (item: any) => (item.status == 'DRAFT' || item.status == 'SUBMITTED' || item.stageDesc == null || item.status == 'COMPLETED') ? '-' : item.stageDesc,
      type: 'objstring'
    },

    {
      name: 'statusDesc',
      displayName: 'enaPurchaseOrder.list.status',
      type: 'options',
      badgeColors: {
        'Draft': 'badge-primary',
        'In Progress': 'badge-secondary',
        'Approved': 'badge-success',
        'Submitted': 'badge-info',
        'Rejected': 'badge-danger',
        'Clarification': 'badge-warning',
        'Pending': 'badge-warning',
        'Accepted': 'badge-success',
        'Completed':'badge-success',
        'Expired' : 'badge-danger'
      }
    },
    // {
    //   name: 'isActive',
    //   displayName: 'master.common.status',
    //   type: 'boolean',
    //   optionVal: ['Active', 'In-Active']
    // },
  ];
  public static columns3 = [
    {
      name: 'id',
      displayName: 'S.No.',
      type: 'number'
    },

    {
      name: "createdDate",
      displayName: 'enaPurchaseOrder.list.date',
      type: "date",
      dateFormat: 'dd/MM/yyyy',
    },
    {
      name: 'applicationNumber',
      displayName: 'Application Number',
      type: 'string'
    },
    {
      name: 'purchaseOrderNo',
      displayName: 'Purchase Order Number',
      type: 'string'
    },
    {
      name: 'purpose',
      displayName: 'Purpose',
      type: 'string'
  },
    {
      name: 'requestQty',
      displayName: 'enaPurchaseOrder.list.reqQuantity',
      type: 'string'
    },
    {
      name: 'purchaserUnitName',
      displayName: 'enaPurchaseOrder.list.purchaserName',
      type: 'string'
    },

    {
      name: 'sellerUnitName',
      displayName: 'enaPurchaseOrder.list.sellerName',
      type: 'string'
    },

    {
      name: "acceptanceStageDesc",
      displayName: 'language.currentlywith',
      displayFcn: (item: any) => (item.acceptanceStatus == 'DRAFT' ||item.acceptanceStatus == 'APPROVED' || item.acceptanceStatus == 'SUBMITTED' || item.acceptanceStatus == null || item.acceptanceStatus == 'PENDING'|| item.acceptanceStatus == 'SENDBACK') ? '-' : item.acceptanceStageDesc,
      type: 'objstring'
    },

    {
      name: 'acceptanceStatusDesc',
      displayName: 'enaPurchaseOrder.list.status',
      type: 'options',
      badgeColors: {
        'Draft': 'badge-primary',
        'In Progress': 'badge-secondary',
        'Approved': 'badge-success',
        'Submitted': 'badge-info',
        'Rejected': 'badge-danger',
        'Clarification': 'badge-warning',
        'Pending': 'badge-warning',
        'Accepted': 'badge-success',
        'Completed':'badge-success',
        'Expired' : 'badge-danger'
      }
    },
    // {
    //   name: 'isActive',
    //   displayName: 'master.common.status',
    //   type: 'boolean',
    //   optionVal: ['Active', 'In-Active']
    // },
  ];

  
}