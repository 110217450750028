import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@appEnv/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LabelrequestplanService {
  baseURL = environment.wholesaleNewURL;
  constructor(private http: HttpClient) { 
    
  }
  wholesaleNewURL = environment.wholesaleNewURL;

  get(data): Observable<any> {
    const url = this.baseURL + 'customdata/getdata';
    return this.http.post(url, data);
  }
  saverequest(data): Observable<any> {
    const url = this.baseURL + 'labelrequest/saverequest';
    return this.http.post(url, data);
  }
  gettppass(data): Observable<any> {
    const url = this.baseURL + 'transportpass/viewtransportpassitems';
    return this.http.post(url, data);
  }
  approve(postData): Observable<any> {
    const url = this.wholesaleNewURL + "approvalflow/process";
    return this.http.post(url, postData)
  }

  saveLabelRequestPayment(postData): Observable<any> {
    const url = this.wholesaleNewURL + "labelrequest/labelrequestpayment";
    return this.http.post(url, postData);
  }

  entityAppKey(data): Observable<any> {
    const url = this.baseURL + 'appconfig/getentityappconfigvalue'
    return this.http.post(url, data);
  }

}
