export class FieldOfficerconfig {

    public static columns = [
      {
        name: 'id',
        displayName: 'language.s.no',
        type: 'number'
      },
      {
        name: "district",
        displayName: "language.district",
        type: "string",
      },
      {
        name: "fieldorsector",
        displayName: 'language.fieldsector',
        type: "string",
      },
      {
        name: "exciseofficialName",
        displayName: 'language.name_of_field_Officer_excise',
        type: "string"
      },
      {
        name: "designation",
        displayName: "language.designation",
        type: "string",
      },
    ];
  }