import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '@appEnv/environment';
import { apiUrls } from '@appEnv/apiurls';

@Injectable({
  providedIn: 'root'
})
export class enaMolassesAdjustmentService {
    constructor(private http: HttpClient) { }
    baseURL = environment.parentUnit;
    devisionUrl = environment.devisionUrl;
    licenseURL = environment.licneseBaseUrl;
    masterURL = environment.devisionUrl;


    addEnaMolassesAdjustment(postdata): Observable<any> {
        const url = this.baseURL + 'enaMolassesAdjustment';
       //const url ="http://65.0.150.210:3501/scmProduction/enaPurchaseOrder";
       
        return this.http.post(url, postdata);
    }

    getDistilleryGroupName(entityType): any {
        const url = this.licenseURL + 'license/category/distilleryGroupNames/'+entityType +'?page=0&size=0';
        return this.http.get(url).toPromise();
    }
    getDistilleryGroupNameByLicenseNumber(entityType,licenseNumber): any {
        const url = this.licenseURL + 'license/category/distilleryGroupNames/'+entityType +'?licenseNumber='+licenseNumber+'&page=0&size=0';
        return this.http.get(url).toPromise();
    }
    getDistilleryGroupUnitName(entityType,groupName): any {
        const url = this.licenseURL + 'license/category/distilleryGroupNames/'+entityType +'?groupName='+groupName+'&page=0&size=0';
        return this.http.get(url).toPromise();
    }
    downloadenavalue(payload) {
        const url = this.baseURL + 'reportReceipt/downloadReceipt'
        // const url = 'http://localhost:3501/scmProduction/reportReceipt/downloadReceipt';
        return this.http.get(url, { responseType: 'blob', params: payload });
      }

    getdetails(): Observable<any> {//work
        //const url = this.licenseURL + 'license/category/DISTILLERY';
        const url = this.licenseURL + 'license/category/BOTTLING';// nisha ma'am
        return this.http.get(url);
    }

    // getapplicationnumber(data): Observable<any> {
    //     const url = this.baseURL + 'generateApplicationNumber?entityType=' +'PD' + '&registrationType=' + data.registrationType ;+ '&licenseNumber=' + data.licenseNumber;
    //     return this.http.post(url, {});

    // }


    getDataById(id): Observable<any> {
        const url = this.baseURL + 'enaMolassesAdjustment/' + id;
        return this.http.get(url);
    }

    getCommonConfig(entityType, type): Observable<any> {
        const url = this.baseURL + 'getCommonConfigDetail?entityType='+entityType+'&type='+type;
        return this.http.get(url);
    }

    getStatusMasters() {
        const url = this.masterURL + "api/masterValues/findDropDownMaster?dropDownKey=WHOLESALE_STATUS_INDENTREQ"
        return this.http.get(url)
    }

    EnaPurchaseOrderDetails(payload) {
        const url = this.baseURL + "distillerySpiritPurchase/lazySearch"
        return this.http.post(url, payload);
    }
    getOrderById1(id): Observable<any> {
        const url = this.baseURL + 'distillerySpiritPurchase/'+id;
        return this.http.get(url);
      }
    search(payload)
    {
        
        const url = this.baseURL + "enaMolassesAdjustment/lazySearch"
        return this.http.post(url, payload);
    }
    getMolassesYear()
    {
        const url = this.licenseURL + "license/findMolassessYear"
        return this.http.get(url)
    }

    getAllotmenNumber(financialYear)
    {
        const url = this.baseURL + "enaMolassesAdjustment/getAllotmentNumber?financialYear=" + financialYear;
        return this.http.get(url, financialYear);
    }

    // getapplicationnumbers(data): Observable<any> {
    //     const url = this.baseURL + 'generateApplicationNumber?registrationType= ' + data;
    //     return this.http.post(url, data);
    // }

    getapplicationNumber(data): Observable<any> {
        // const url = this.baseURL + 'generateApplicationNumber?registrationType='+ data;
        // return this.http.post(url, data);

        const url = this.baseURL + 'generateApplicationNumber?entityType=' +data.entityType + '&registrationType=' + data.registrationType ;+ '&licenseNumber=' + data.licenseNumber;
        return this.http.post(url, {});

    }
    getPdCardCount(payload): Observable<any> {
        const url = this.baseURL + 'pd/getStatusCount'
        return this.http.post(url,payload);
      }
}