import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '@appEnv/environment';
import { apiUrls } from '@appEnv/apiurls';

@Injectable({
    providedIn: 'root'
})
export class UndefinedPenaltiesService {

    constructor(private http: HttpClient) { }

    baseURL = environment.parentUnit;
    devisionUrl = environment.devisionUrl;
    licenseURL = environment.licneseBaseUrl;
    domainUrl = environment.domainUrl;

    addetails(data): Observable<any> {
        const url = this.baseURL + 'penalityUndefinedSubject';
        return this.http.post(url, data);
    }
    search(payload): Observable<any> {
        const url = this.baseURL + 'penalityUndefinedSubject/lazySearch';
        return this.http.post(url, payload);
    }
    getById(id): Observable<any> {
        const url = this.baseURL + 'penalityUndefinedSubject/' + id;
        return this.http.get(url);
    }
    getapplicationnumbers(data): any {
        const url = this.baseURL + 'generateApplicationNumber?registrationType=' + data;
        return this.http.post(url, data);
    }
    getLicenseData(category): Observable<any> {
        const url = this.licenseURL + 'license/category/' + category;
        return this.http.get(url);
    }
    getEntityType(url): any {
        let entityType = url.split('/')[1];
        entityType == 'ind' ? entityType = 'ID' : entityType
        return entityType.toUpperCase()
    }
    // payment related api's
    saveupdatepaymentdetails(payload): Observable<any> {
        const url = this.baseURL + 'penalityUndefinedSubject/saveUpdatePaymentDetails';
        return this.http.post(url, payload);
    }
    findpaymentdetails(applicationno, feetype): Observable<any> {
        // let url = this.baseURL + 'sampleRequest/findPaymentDetails?applicationNumber=' + applicationno + '&feeType=' + feetype;
        let url = this.baseURL + 'penalityUndefinedSubject/findPaymentDetails?applicationNumber=' + applicationno + '&feeType=' + feetype;
        return this.http.get(url);
    }
    findAllPaymentDetails(applicationno, feetype): Observable<any> {
        // let url = this.baseURL + 'sampleRequest/findPaymentDetails?applicationNumber=' + applicationno + '&feeType=' + feetype;
        let url = this.baseURL + 'penalityUndefinedSubject/findAllPaymentDetails?applicationNumber=' + applicationno + '&feeType=' + feetype;
        return this.http.get(url);
    }
    licenceAdd(payload): Observable<any> {
        const url = this.domainUrl + 'payandrecon/1.0/license/multipleadd';
        return this.http.post(url, payload);
    }
    downloadDocument(appnumber, liceanseNumber, feeType): any {
        const url = this.baseURL + 'reportReceipt/downloadSampleFeeReceipt' + '?applicationNumber=' + appnumber + '&licenseNumber=' + liceanseNumber + '&feeType=' + feeType;
        return this.http.get(url, { responseType: 'blob' });
    }
    getPaymentDetail(payload): Observable<any> {
        const url = this.domainUrl + 'payandrecon/1.0/config/sub/search';//'config/sub/search';
        return this.http.post(url, payload);
    }
    getQrCodeDetail(appnumber): Observable<any> {
        const url = this.baseURL + 'penalityUndefinedSubject/getQrCodeDetail' + '?applicationNumber=' + appnumber;
        return this.http.get(url);
    }
}
