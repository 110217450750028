import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { apiUrls } from '@appEnv/apiurls';
import { environment } from '@appEnv/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ReceivefromginplantService {

  constructor(
    private http: HttpClient
  ) { }

  baseURL = environment.parentUnit;
  devisionUrl = environment.devisionUrl
  masterurl = environment.baseURLMasterIpAddr
  licenseURL = environment.licneseBaseUrl;


  addDetails(data): Observable<any> {
    const url = this.baseURL + 'ginTransfer';
    console.log(url, data)
    return this.http.post(url, data);
  }

  searchTanReg(postdata): Observable<any> {
    const url = this.baseURL + 'breweryTankRegistration/tankRegistrationLazySearch';
    return this.http.post(url, postdata);
  }
  getById(id): Observable<any> {
    const url = this.baseURL + 'ginTransfer/' + id;
    return this.http.get(url);
  }
  getDropdownValues(params): Observable<any> {
    const url = this.devisionUrl + apiUrls.findDropDownMasterOnMaster
    return this.http.get(url, { params: params });
  }
  getSourceTanks(data: any): Observable<any> {
    const url = this.baseURL + 'breweryTankRegistration/tankRegistrationLazySearch';
    return this.http.post(url, data);
  }

  getdetails(): any {//work
    const url = this.licenseURL + 'license/category/DISTILLERY';
    return this.http.get(url).toPromise();

  }
  getApplicationNo(data: any): Observable<any> {
    const url = this.baseURL + 'generateApplicationNumber?registrationType=' + data.registrationType + '&licenseNumber=' + data.licenseNumber;
    return this.http.post(url, data);
  }
  downloadDocument(appnumber, id, receiptType): any {
    const url = this.baseURL + 'reportReceipt/downloadReceipt' + '?applicationNumber=' + appnumber + '&id=' + id + '&receiptType=' + receiptType;
    return this.http.get(url, { responseType: 'blob' });
  }
  search(payload): Observable<any> {
    const url = this.baseURL + 'ginTransfer/lazySearch';
    return this.http.post(url, payload);
}
  getReceiverCount(entityType?): Observable<any> {
    const url = this.baseURL + 'ginTransfer/getStatusCount?entityType=' +entityType +'&ginTransferType=RECEIVE_FROM_GIN_PLANT';
    return this.http.post(url, entityType)
  }
  downloadinspection(payload){
    const url = this.baseURL + 'reportReceipt/downloadReceipt'
      // const url = 'http://localhost:3501/scmProduction/reportReceipt/downloadReceipt';
    return this.http.get(url,{ responseType: 'blob', params:payload });
  }
  getPdCardCount(payload): Observable<any> {
    const url = this.baseURL + 'pd/getStatusCount'
    return this.http.post(url,payload);
  }
  getPlantTotal(entityType?): Observable<any> {
    const url = this.baseURL + 'ginTransfer/getPlanTotalQuantity?entityType=' +entityType;
    return this.http.post(url, {})
  }
}
