export class orderpickconfig {


    public static pendings = [
        {
            name: 'id',
            displayName:'districtwarehouse.orderpickup.sno',
            type: 'number'
        },
        // here we need to change the display name  once api i sredy
        {
            name: 'tp_date',
            displayName: 'districtwarehouse.orderpickup.applicationdate',
            displayFcn: (item: any) => item.tp_date,
            type: 'date',
            // dateFormat: 'dd-MMM-yyyy',
        },
        // here we need to change the display name once api i sredy
        {
            name: 'tp_no',
            displayName: 'districtwarehouse.orderpickup.transportPassNo',
            displayFcn: (item: any) => item.tp_no,
            type: 'objstring'
        },

        {
            name: 'unit_name',
            displayName: 'districtwarehouse.orderpickup.unitname',
            displayFcn: (item: any) => item.unit_name,
            type: 'objstring'
        },
        {
            name: 'requested_cases',
            displayName: 'districtwarehouse.orderpickup.requestedcases',
           displayFcn: (item: any) => item.requested_cases,
            type: 'objstring',
            align: 'center'
        },
        {
            name: 'requested_qty',
            displayName: 'districtwarehouse.orderpickup.requestedbottles',
            displayFcn: (item: any) => item.requested_qty,
            type: 'objstring',
            align: 'center'
        },

        
    ];
    public static Inprogress = [
        {
            name: 'id',
            displayName:'districtwarehouse.orderpickup.sno',
            type: 'number'
        },
        // here we need to change the display name  once api i sredy
        {
            name: 'tp_no',
            displayName: 'districtwarehouse.orderpickup.transportPassNo',
            displayFcn: (item: any) => item.tp_no,
            type: 'objstring'
        },
        {
            name: 'tp_date',
            displayName: 'Order Pick Up Date',
            displayFcn: (item: any) => item.tp_date,
            type: 'date',
            // dateFormat: 'dd-MMM-yyyy',
        },
        // here we need to change the display name once api i sredy
        {
            name: 'unit_name',
            displayName: 'districtwarehouse.orderpickup.unitname',
            displayFcn: (item: any) => item.unit_name,
            type: 'objstring'
        },
        {
            name: 'requested_qty',
            displayName: 'districtwarehouse.orderpickup.requestedbottles',
            displayFcn: (item: any) => item.requested_qty,
            type: 'objstring',
            align: 'center'
        },
        {
            name: 'requested_cases',
            displayName: 'districtwarehouse.orderpickup.requestedcases',
           displayFcn: (item: any) => item.requested_cases,
            type: 'objstring',
            align: 'center'
        },
        {
            name: 'tp_status',
            displayName: 'Status',
            displayFcn: (item: any) => item.tp_status,
            type: 'objstring',
            align: 'center'
        },
        {
            name: 'release_status',
            displayName: 'Release Status',
            displayFcn: (item: any) => item.release_status,
            type: 'objstring',
            align: 'center'
        },
    ];
    public static completetd = [
        {
            name: 'id',
            displayName:'districtwarehouse.orderpickup.sno',
            type: 'number'
        },
        // here we need to change the display name  once api i sredy
        {
            name: 'tp_date',
            displayName: 'districtwarehouse.orderpickup.applicationdate',
            displayFcn: (item: any) => item.tp_date,
            type: 'date',
            // dateFormat: 'dd-MMM-yyyy',
        },
        // here we need to change the display name once api i sredy
        {
            name: 'tp_no',
            displayName: 'districtwarehouse.orderpickup.transportPassNo',
            displayFcn: (item: any) => item.tp_no,
            type: 'objstring'
        },

        {
            name: 'unit_name',
            displayName: 'districtwarehouse.orderpickup.unitname',
            displayFcn: (item: any) => item.unit_name,
            type: 'objstring'
        },
        {
            name: 'requested_cases',
            displayName: 'districtwarehouse.orderpickup.requestedcases',
           displayFcn: (item: any) => item.requested_cases,
            type: 'objstring',
            align: 'center'
        },
        {
            name: 'requested_qty',
            displayName: 'districtwarehouse.orderpickup.requestedbottles',
            displayFcn: (item: any) => item.requested_qty,
            type: 'objstring',
            align: 'center'
        },
    ];
}