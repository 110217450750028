export class ShopConfig {
  
    public static columns = [
      {
        name: 'id',
        displayName: '#',
        type: 'number'
      },
      {
        name: 'shopId',
        displayName: 'Shop Code',
        type: 'string'
      },
      {
        name: 'nameOfShop',
        displayName: 'Shop Name',
        type: 'string'
      },
      // {
      //   name: 'nameOfLicensee',
      //   displayName: 'License Name',
      //   type: 'string'
      // },
      {
        name: 'districtDesc',
        displayName: 'District',
        type: 'string'
      },
      // {
      //   name: 'status',
      //   displayName: 'master.common.status',
      //   type: 'boolean',
      //   optionVal: ['Active', 'In-Active']
      // }
    ];

    public static registerRetailShopListColumns = [
      {
        name: 'id',
        displayName: '#',
        type: 'number'
      },
      {
        name: 'shopId',
        displayName: 'Shop ID',
        type: 'string'
      },
      {
        name: 'shopName',
        displayName: 'Shop Name',
        type: 'string'
      },
      {
        name: 'stateDesc',
        displayName: 'State',
        type: 'string'
      },
      {
        name: 'districtDesc',
        displayName: 'District',
        type: 'string'
      },
      {
        name: 'tehsilDesc',
        displayName: 'Tehsil',
        type: 'string',
      },
      // {
      //   name: 'station',
      //   displayName: 'Station',
      //   type: 'string',
      // },
      // {
      //   name: 'circle',
      //   displayName: 'Circle',
      //   type: 'string',
      // },
      {
        name: 'selectorDesc',
        displayName: 'Sector',
        type: 'string',
      },
      {
        name: 'areaDesc',
        displayName: 'Area',
        type: 'string',
      }
    ];

    public static schedulerColumns = [
      {
        name: 'id',
        displayName: '#',
        type: 'number'
      },
      {
        name: 'schedulerNumber',
        displayName: 'Scheduler number',
        type: 'string'
      },
      {
        name: 'financialYear',
        displayName: 'Financial Year',
        type: 'string'
      },
      {
        name: 'zone',
        displayName: 'Zone',
        type: 'string'
      },
      {
        name: 'totalDistrict',
        displayName: 'Total District',
        type: 'string'
      },
      {
        name: 'totalLicenseType',
        displayName: 'Total License Type',
        type: 'string',
      },
      {
        name: 'totalShops',
        displayName: 'Total Shops',
        type: 'string',
      },
      {
        name: 'schedulerDate',
        displayName: 'Date',
        type: 'string',
      }
      ,
      {
        name: 'schedulerTime',
        displayName: 'Time',
        type: 'string',
      },
      {
        name: 'schedulerInterval',
        displayName: 'Interval',
        type: 'string',
      }
    ];

    public static beerPermitColumns = [
      {
        name: 'id',
        displayName: '#',
        type: 'number'
      },
      {
        name: 'applicationNumber',
        displayName: 'Application Number',
        type: 'string'
      },
      {
        name: 'shopId',
        displayName: 'Shop Id',
        type: 'string'
      },
      {
        name: 'shopName',
        displayName: 'shop Name',
        type: 'string'
      },
      {
        name: 'districtDesc',
        displayName: 'District',
        type: 'string'
      },
      {
        name: 'stage',
        displayName: 'Currently With',
        type: 'string'
      },
      {
        name: 'statusDesc',
        displayName: 'Application Status',
        type: 'string'
      }
    ] 
    public static eventbarOnetimeRegistrationColumns = [
      {
        name: 'id',
        displayName: '#',
        type: 'number'
      },
      {
        name: 'applicationNumber',
        displayName: 'Application Number',
        type: 'string'
      },
      {
        name: 'registrationNumber',
        displayName: 'Registration Number',
        type: 'string'
      },
      {
        name: 'financialYear',
        displayName: 'Financial Year',
        type: 'string'
      },
      {
        name: 'unitName',
        displayName: 'Unit Name',
        type: 'string'
      },
      {
        name: 'districtDesc',
        displayName: 'District',
        type: 'string'
      },
      {
        name: 'stage',
        displayName: 'Currently With',
        type: 'string'
      },
      {
        name: 'statusDesc',
        displayName: 'Application Status',
        type: 'string'
      }
    ]
  }
  