import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '@appEnv/environment';
import { apiUrls } from '@appEnv/apiurls';

@Injectable({
    providedIn: 'root'
})

export class SampleRequestService {

    baseURL = environment.parentUnit;

    constructor(private http: HttpClient) { }

    searchSampleRequest(postdata): Observable<any> {
        const url = this.baseURL + apiUrls.mf5Part3Search;
        return this.http.post(url, postdata);
    }
    getSampleRequestById(id): Observable<any> {
        const url = this.baseURL + apiUrls.mfpart3GetById + id;
        return this.http.get(url);
    }
    addSampleRequest(postdata): Observable<any> {
        const url = this.baseURL + apiUrls.mfpart3Add;
        return this.http.post(url, postdata);
    }
    updateSampleRequest(postdata): Observable<any> {
        const url = this.baseURL + apiUrls.mfpart3GetUpdate;
        return this.http.put(url, postdata);
    }
    gettanksDetails(postdata): Observable<any> {
        const url = this.baseURL + apiUrls.gettanksbyid;
        return this.http.post(url, postdata);
    }
    getStockInDetail(postdata): Observable<any> {
        const url = this.baseURL + apiUrls.getStockInDetail;
        return this.http.post(url, postdata);
    }
    getapplicationnumbers(data): Observable<any> {
        const url = this.baseURL + 'generateApplicationNumber?registrationType= ' + data;
        return this.http.post(url, data);
    }
    getYear(): Observable<any> {
        const url = this.baseURL + 'utility/molasses/forecastDetails';
        return this.http.get(url);
    }
}
