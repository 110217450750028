import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '@appEnv/environment';
import { apiUrls } from '@appEnv/apiurls'



@Injectable({
    providedIn: 'root'
  })
  export class ExternalauditorService{
    baseURL = environment.parentUnit;
    licenseURL = environment.licneseBaseUrl;
    constructor(private http: HttpClient) { }
    getAllExternalauditor(postdata): Observable<any> {
      const url = this.baseURL + 'auditManagement/lazySearch';
      return this.http.post(url,postdata);
    }
    getAllMf7Details(postdata): Observable<any> {
      const url = this.baseURL + 'mf7Sugarmill/lazySearch';
      return this.http.post(url,postdata);
    }
    getExternalauditorById(id): Observable<any> {
      const url = this.baseURL + apiUrls.externalauditorById + id;
      return this.http.get(url);
    }   
    getWastage(id): Observable<any> {
      const url = this.baseURL + 'mf7Sugarmill/getMstDetailsByTankId';
      return this.http.get(url);
    }
    addExternalauditor(postdata): Observable<any> {
      const url = this.baseURL + 'auditManagement';
      return this.http.post(url, postdata);
    }
    getMf1Report(postdata): Observable<any> {
      const url = this.baseURL + apiUrls.mf1ReportGet;
      return this.http.post(url, postdata);
    }
    externalauditorGetUpdateWorkFlow(postdata): Observable<any> {
      const url = this.baseURL + apiUrls.externalauditorGetUpdateWorkFlow;
      return this.http.put(url, postdata);
    }
    searchMf1Report(postdata): Observable<any> {
      const url = this.baseURL + apiUrls.mf1ReportSearch;
      return this.http.post(url, postdata);
    }
    getDownload(): Observable<any> {
      const url = this.baseURL + apiUrls.allotmentexportExcel;
      return this.http.get(url, { responseType: 'blob' });
    }
    getPrintData(urls: any): Observable<any> {
      const url = this.baseURL + urls;
      return this.http.get(url, { responseType: 'blob' });
    }
    gettanksDetails(postdata): Observable<any> {
      const url = this.baseURL + apiUrls.gettanksbyid;
      return this.http.post(url, postdata);
    }
    getStockInDetail(postdata): Observable<any> {
      const url = this.baseURL + apiUrls.getStockInDetail;
      return this.http.post(url, postdata);
    }
    getapplicationnumbers(data): Observable<any> {
      const url = this.baseURL + 'generateApplicationNumber?registrationType=' + data;
      return this.http.post(url, data);
    }
    getYear(): Observable<any> {
      const url = this.baseURL + 'utility/molasses/forecastDetails';
      return this.http.get(url);
    }

    getUnitDetails(entity,licNum):Observable<any> {
      const url = this.licenseURL + 'license/category/' + entity+ '?licenseNumber=' + licNum;
      return this.http.get(url);
  }
  downloadenavalue(payload){
    const url = this.baseURL + 'reportReceipt/downloadReceipt' ;
    return this.http.get(url,{ responseType: 'blob', params:payload });
  }
}