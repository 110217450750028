import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { apiUrls } from '@appEnv/apiurls';
import { saveAs } from 'file-saver';
@Injectable({
  providedIn: 'root'
})
export class RolloveraddpageService {
  constructor(
    private http: HttpClient
  ) { }

  wholesaleNewURL = environment.wholesaleNewURL;

  payandreconBaseUrl = environment.payandreconBaseUrl;

  getdata(obj): Observable<any> {
    const url = this.wholesaleNewURL + 'customdata/getdata';
    return this.http.post(url, obj);
  }

  getdataPromise(postParam): any {
    const url = this.wholesaleNewURL + 'customdata/getdata'
    return this.http.post(url, postParam).toPromise();
  }

  stockRollOverPayment(postParam): any {
    const url = this.payandreconBaseUrl + 'payandrecon/1.0/' + apiUrls.callRajkoshToRedirectURL;
    return this.http.post(url, postParam);
  }

  saveStockRollOver(postParam): any {
    const url = this.wholesaleNewURL + 'stockrolloverregister/addstockrolloverregister';
    return this.http.post(url, postParam);
  }

  gettreasuryheadandG6(postParam): any {
    const url = this.payandreconBaseUrl + 'payandrecon/1.0/SubFeesAndG6Master/gettreasuryheadandG6';
    return this.http.post(url, postParam);
  }

  multiAdd(postParam): any {
    const url = this.payandreconBaseUrl + 'payandrecon/1.0/license/multipleadd';
    return this.http.post(url, postParam);
  }

  processApi(postParam): any {
    const url = this.wholesaleNewURL + 'approvalflow/process';
    return this.http.post(url, postParam);
  }
  approveData(postParam): any {
    const url = this.wholesaleNewURL + 'approvalflow/processwithstockrolloverpaymentupdate';
    return this.http.post(url, postParam);
  }
  saveData(postParam): any {
    const url = this.wholesaleNewURL + 'customdata/savedata';
    return this.http.post(url, postParam);
  }
  downloadTP() {
    const url = this.wholesaleNewURL + 'filedownload/edpebptemplatefile';
    return this.http.get(url, { responseType: 'blob' });
  }

  fileSaver(blob: any) {
    const file = new Blob([blob], { type: 'application/vnd.ms-excel' }); // Change the file type based on your needs
    saveAs(file, 'ebp_edp_stockrollover.xlsx'); // Change the file name based on your needs
  }

  // uploadxl(imageFile: File): Observable<any> {
  //   const apiUrl =this.payandreconBaseUrl + 'retailpos/v1.0.0/datafile/uploadfile'; // Replace with your API endpoint URL
  //   const formData = new FormData();
  //   formData.append('image', imageFile);
  //   return this.http.post(apiUrl, formData);
  // }

  // uploadxl(xlFile: File): Observable<any> {
  //   const apiUrl = this.payandreconBaseUrl + 'retailpos/v1.0.0/datafile/uploadfile';
  //   const formData = new FormData();
  //   formData.append('xlFile', xlFile, xlFile.name);
  //   console.log("apiUrl",apiUrl)
  //   return this.http.post(apiUrl, formData);
  // }

  uploadxl(dataFileRegister: string, dataXLFile: File): Observable<any> {
    const apiUrl = this.wholesaleNewURL + 'datafile/uploadfile';
    const formData = new FormData();
    formData.append('dataFileRegister', dataFileRegister);
    formData.append('dataXLFile', dataXLFile);
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    console.log("apiUrl", apiUrl)
    return this.http.post(apiUrl, formData, { headers });
  }

  addConfig(payload): any {
    const apiURL = this.wholesaleNewURL + 'clconfig/addclconfig'
    return this.http.post(apiURL, payload)
  }
  districtdropdown(postdata): Observable<any> {
    const apiURL = this.wholesaleNewURL + 'customdata/getdata'
    return this.http.post(apiURL, postdata)
  }
  licensedropdown(postdata): Observable<any> {
    const apiURL = this.wholesaleNewURL + 'customdata/getdata'
    return this.http.post(apiURL, postdata)
  }
  listcli(postdata): Observable<any> {
    const apiURL = this.wholesaleNewURL + 'customdata/getdata'
    return this.http.post(apiURL, postdata)
  }
  listrecord(postdata): any {
    const apiURL = this.wholesaleNewURL + 'customdata/getdata'
    return this.http.post(apiURL, postdata).toPromise();
  }
  entitydropdowns(postdata): Observable<any> {
    const apiURL = this.wholesaleNewURL + 'customdata/getdata'
    return this.http.post(apiURL, postdata)
  }
  addStockOpeningBalance(payload): Observable<any> {
    const apiURL = this.wholesaleNewURL + 'stockverificationdetails/addstockverificationdetails'
    return this.http.post(apiURL, payload)
  }
  deleteStockOpeningBalance(payload: any) {
    const apiURL = this.wholesaleNewURL + 'stockverificationdetails/deletestockverificationdetails';
    return this.http.post(apiURL, payload);
  }
  completedStockOpeningBalance(payload: any) {
    const apiURL = this.wholesaleNewURL + 'stockverificationdetails/updatestockverificationdetails';
    return this.http.post(apiURL, payload)
  }
  loadOpeningStockBalance(payload: any) {
    const apiURL = this.wholesaleNewURL + 'stockverificationdetails/addmonthlystocktakendetails';
    return this.http.post(apiURL, payload)
  }
  completedMonthlyStockTaken(payload: any) {
    const apiURL = this.wholesaleNewURL + 'stockverificationdetails/updatemonthlystocktakendetails';
    return this.http.post(apiURL, payload)
  }
  getAppConfig(payload: any) {
    const apiURL = this.wholesaleNewURL + 'appconfig/getappconfigvalue';
    return this.http.post(apiURL, payload)
  }
  getStockInHand(payload: any) {
    const apiURL = this.wholesaleNewURL + 'stockdata/stockinhandbyentitycode';
    return this.http.post(apiURL, payload)
  }
  getAppConfigValueEntityWise(postParam): any {
    const url = this.wholesaleNewURL + 'appconfig/getentityappconfigvalue'
    return this.http.post(url, postParam).toPromise();
  }
  getStockRollover(postParam): any {
    const url = this.wholesaleNewURL + 'stockdata/ebpedpstockrolloverstockavailability';
    return this.http.post(url, postParam);
  }
  getAppConfigValue(postData): Observable<any> {
    const url = this.wholesaleNewURL + "appconfig/getappconfigvalue";
    return this.http.post(url, postData);
  }
  stockVerificationFileUpload(data): Observable<any> {
    let headers = new HttpHeaders();
    const url = this.wholesaleNewURL + 'stockverificationdetails/savestockverificationfileandrecords';
    return this.http.post(url, data, { headers: headers });
  }

  uploadRolloverFile(data): Observable<any> {
    console.log('data', data)
    let headers = new HttpHeaders();
    const url = this.wholesaleNewURL + 'stockrolloverregister/savescheduleprocess';
    return this.http.post(url, data, { headers: headers });
  }
  getEntityAppConfig(postParam): Observable<any> {
    const url = this.wholesaleNewURL + 'appconfig/getentityappconfigvalue'
    return this.http.post(url, postParam);
  }

  stockRollOverSubmitSheduller(postParam): Observable<any> {
    const url = this.wholesaleNewURL + 'stockrolloverregister/reprocessschedule'
    return this.http.post(url, postParam);
  }

  loadOpeningStockBalanceForRetail(payload: any) {
    const apiURL = this.wholesaleNewURL + 'stockverificationdetails/addmonthlystocktakendetailsforretail';
    return this.http.post(apiURL, payload)
  }

  deleteStockOpeningBalanceForRetail(payload: any) {
    const apiURL = this.wholesaleNewURL + 'stockverificationdetails/deletemonthlystocktakendetailsforretail';
    return this.http.post(apiURL, payload);
  }

  completedMonthlyStockTakenForRetail(payload: any) {
    const apiURL = this.wholesaleNewURL + 'stockverificationdetails/updatemonthlystocktakendetailsforretail';
    return this.http.post(apiURL, payload)
  }
}
