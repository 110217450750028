export class ReturnfilingWholesalebeerconfig {

    // public static titleSection = {
    //   list: {
    //       title: 'master.district.list',
    //       button: {
    //           text: 'master.district.adddistrict',
    //           url: '/location/district/add'
    //       }
    //   },
    //   add: {
    //       title: 'master.district.list'
    //   },
    //   edit: {
    //       title: 'master.district.list'
    //   },
    //   view: {
    //       title: 'master.district.list'
    //   }
    // };
  
    // public static breadcrumbs = [
    //   {
    //     text: 'language.chemicalLab',
    //     url: '/returnfiling/return-filing-wholesale-beer/list',
    //     hasHref: true
    //   },
    //   {
    //     text: 'language.samplerequest',
    //     url: '/returnfiling/return-filing-wholesale-beer/list',
    //     hasHref: true
    //   } 
    // ];
  
    public static columns = [
      {
        name: 'id',
        displayName: 'language.s.no',
        type: 'number'
      },
      {
        name: "year",
        displayName: 'language.year',
        type: "string",
      },
      {
        name: "createdDate",
        displayName: "language.dateofapp",
        type: "date",
        dateFormat: 'dd/MM/yyyy',
      },
      {
        name: "applicationNumber",
        displayName: 'language.appnum',
        type: "string",
      },
      {
        name: 'licencetype',
        displayName: 'language.licencetype',
        type: "string",
      },
      {
        name: "applicantname",
        displayName: 'language.applicantname',
        type: "string",
      },
      {
        name: "district",
        displayName: "language.district",
        type: "string",
      },      
      {
        name: 'statusDesc',
        displayName: 'language.applicationstatus',
        type: 'options',
        badgeColors: {
          'Draft': 'badge-primary',
          'In Progress': 'badge-secondary',
          'Approved': 'badge-success',
          'Submitted': 'badge-info',
          'Rejected': 'badge-danger',
          'Clarification': 'badge-warning'
        }
      },
    ];
  }