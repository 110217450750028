import * as moment from "moment";

export class Accidentalrequestconfig {

    public static columns = [
        {
            name: 'id',
            displayName: 'Request Id',
            type: 'string'
        },
        {
            name: 'created_date',
            displayName: 'Request Date',
            displayFcn: (item: any) => moment(item.created_date).format("DD-MM-YYYY"),
            type: 'objstring'
        },
        {
            name: 'accident_type_value',
            displayName: 'Accident Type',
            type: 'string'
        },
        {
            name: 'district_name',
            displayName: 'Accident Locaion District',
            type: 'string'
        },
        {
            name: 'tp_reference_number',
            displayName: 'Transport Pass Number',
            type: 'string'
        },
        {
            name: 'status',
            displayName: 'Status',
            displayFcn: (item: any) => item.status,
            type: 'options',
            badgeColors: {
                "ACCIDENT_SUBMITTED": 'badge-info',
                "FL36_FINAL_TP_SUBMITTED": 'badgep-secondary',
                "FL36_FINAL_TP_APPROVED": 'badge-success',
                "ACCIDENT_SUBMITTED_IN-PROGRESS": 'badge-success'
            }
        }
    ]
}
