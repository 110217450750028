export class BrewQrConfig {
    public static PendingColumns = [
        // {
        //     name: 'id',
        //     displayName: 'Boxing ID ',
        //     displayFcn: (item: any) => item.id,
        //     type: 'objstring'
        // },

        {
            name: 'date_of_packaging',
            displayName: 'brewery.qrcodeprinting.dateofpackaging',
            displayFcn: (item: any) => item.date_of_packaging,
            type: 'date'
        },
        {
            name: 'bottling_plan_id',
            displayName: 'brewery.qrcodeprinting.bottlingplanid',
            displayFcn: (item: any) => item.bottling_plan_id,
            type: 'objstring'
        },
        {
            name: 'brand_value',
            displayName: 'brewery.qrcodeprinting.brandname',
            displayFcn: (item: any) => item.brand_value,
            type: 'objstring'
        },
        {
            name: 'no_of_cases',
            displayName: 'brewery.qrcodeprinting.noofcases',
            displayFcn: (item: any) => item.no_of_cases,
            type: 'objstring',
            align:'center'
        },
        {
            name: 'no_of_bottles',
            displayName: 'brewery.qrcodeprinting.noofbottles',
            displayFcn: (item: any) => item.no_of_bottles,
            type: 'objstring',
            align:'center'
        },
        {
            name: 'status1',
            displayName: 'brewery.qrcodeprinting.status',
            displayFcn: (item: any) => item.status1,
            type: 'options',
            badgeColors: {
                PENDING: 'badge-success'
            }
        },
    ];
    public static InProgressColumns = [
        // {
        //     name: 'id',
        //     displayName: 'Boxing ID ',
        //     displayFcn: (item: any) => item.id,
        //     type: 'objstring'
        // },

        {
            name: 'date_of_packaging',
            displayName: 'brewery.qrcodeprinting.dateofpackaging',
            displayFcn: (item: any) => item.date_of_packaging,
            type: 'date'
        },
        {
            name: 'bottling_plan_id',
            displayName: 'brewery.qrcodeprinting.bottlingplanid',
            displayFcn: (item: any) => item.bottling_plan_id,
            type: 'objstring'
        },
        {
            name: 'brand_value',
            displayName: 'brewery.qrcodeprinting.brandname',
            displayFcn: (item: any) => item.brand_value,
            type: 'objstring'
        },
        {
            name: 'no_of_cases',
            displayName: 'brewery.qrcodeprinting.noofcases',
            displayFcn: (item: any) => item.no_of_cases,
            type: 'objstring',
            align:'center'
        },
        {
            name: 'no_of_bottles',
            displayName: 'brewery.qrcodeprinting.noofbottles',
            displayFcn: (item: any) => item.no_of_bottles,
            type: 'objstring',
            align:'center'
        },
        {
            name: 'status1',
            displayName: 'brewery.qrcodeprinting.status',
            displayFcn: (item: any) => item.status1,
            type: 'objstring',
            badgeColors: {
                'IN-PROGRESS': 'badge-success'
            }
        },
    ];
    public static CompletedColumns = [
        // {
        //     name: 'id',
        //     displayName: 'Boxing ID ',
        //     displayFcn: (item: any) => item.id,
        //     type: 'objstring'
        // },

        {
            name: 'date_of_packaging',
            displayName: 'brewery.qrcodeprinting.dateofpackaging',
            displayFcn: (item: any) => item.date_of_packaging,
            type: 'date'
        },
        {
            name: 'bottling_plan_id',
            displayName: 'brewery.qrcodeprinting.bottlingplanid',
            displayFcn: (item: any) => item.bottling_plan_id,
            type: 'objstring'
        },
        {
            name: 'brand_value',
            displayName: 'brewery.qrcodeprinting.brandname',
            displayFcn: (item: any) => item.brand_value,
            type: 'objstring'
        },
        {
            name: 'no_of_cases',
            displayName: 'brewery.qrcodeprinting.noofcases',
            displayFcn: (item: any) => item.no_of_cases,
            type: 'objstring',
            align:'center'
        },
        {
            name: 'no_of_bottles',
            displayName: 'brewery.qrcodeprinting.noofbottles',
            displayFcn: (item: any) => item.no_of_bottles,
            type: 'objstring',
            align:'center'
        },
        {
            name: 'status1',
            displayName: 'brewery.qrcodeprinting.status',
            displayFcn: (item: any) => item.status1,
            type: 'options',
            badgeColors: {
                COMPLETED: 'badge-success'
            }
        },
    ];
    public static columnsReport = [
        {
            name: 'id',
            displayName: 'S No',
            type: 'number'
        },
        {
            name: 'dispatchDatee',
            displayName: 'Disaptch Date',
            type: 'date'
        },
        {
            name: 'transportPassNoe',
            displayName: 'Transport Pass No',
            type: 'string'
        },
        {
            name: 'unitNamee',
            displayName: 'Unit Name',
            type: 'string'
        },
        {
            name: 'totScannedCasese',
            displayName: 'Cases Dispatched',
            type: 'string'
        },
        {
            name: 'totScannedBottlese',
            displayName: 'Bottles Dispatched',
            type: 'string'
        },

    ];

    public static retailStockReconciliation = [
        {
            name: 'id',
            displayName: 'S No',
            type: 'number'
        },
        {
            name: 'reference_number',
            displayName: 'Application Number',
            displayFcn: (item: any) => item.reference_number,
            type: 'objstring'
        },
        {
            name: 'financial_year',
            displayName: 'Financial Year',
            displayFcn: (item: any) => item.financial_year,
            type: 'objstring'
        },
        {
            name: 'scanned_cases',
            displayName: 'Scanned Bottles',
            displayFcn: (item: any) => item.scanned_cases,
            type: 'objstring'
        },
        {
            name: 'status',
            displayName: 'brewery.qrcodeprinting.status',
            displayFcn: (item: any) => item.status,
            type: 'options',
            badgeColors: {
                COMPLETED: 'badge-success',
                APPROVED: 'badge-success',
                REJECTED: 'badge-danger',
                SUBMITTED: 'badge-primary',
                CANCELLED_SUBMITTED: 'badge-success'
            }
        },
    ];

}