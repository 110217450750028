import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '@appEnv/environment';
import { apiUrls } from '@appEnv/apiurls';
@Injectable({
  providedIn: 'root'
})
export class DipBookBrewService {

  constructor(
    private http: HttpClient
  ) { }
  baseURL = environment.parentUnit;

  getStatusCount(entityType,isCurrentOfficerAllRecords,isAllOfficerRecords,isOtherOfficerAllRecords): Observable<any> {
    // const url = 'http://localhost:3501/scmProduction/dipBook/getStatusCount?entityType=' + entityType 
    // +'&isCurrentOfficerAllRecords=' + isCurrentOfficerAllRecords + '&isAllOfficerRecords=' + isAllOfficerRecords 
    // +'&isOtherOfficerAllRecords=' +isOtherOfficerAllRecords  ;
    const url = this.baseURL + 'dipBook/getStatusCount?entityType=' + entityType 
    +'&isCurrentOfficerAllRecords=' + isCurrentOfficerAllRecords + '&isAllOfficerRecords=' + isAllOfficerRecords 
    +'&isOtherOfficerAllRecords=' +isOtherOfficerAllRecords  ;
    return this.http.get(url);
  }
  
  searchDipbook(postParam): Observable<any> {
    const url = this.baseURL + 'dipBook/lazySearch';
    return this.http.post(url, postParam);
  }

  findDipbookId(id): Observable<any> {
    const url = this.baseURL + 'dipBook/' + id;
    return this.http.get(url);
  }
  
  devisionUrl = environment.devisionUrl;
  licenseURL = environment.licneseBaseUrl;

  adddDipBookDetails(data): Observable<any> {
      const url = this.baseURL + 'dipBook';
      return this.http.post(url, data);
  }

  dipBookSearch(data): Observable<any> {
      const url = this.baseURL + 'dipBook/lazySearch';
      return this.http.post(url, data);
  }
  updateWorkFlow(payload): Observable<any> {
      const url = this.baseURL + 'dipBook/updateWorkFlow';
      return this.http.post(url, payload);
  }

  getById(id): Observable<any> {
      const url = this.baseURL + 'dipBook/' + id;
      return this.http.get(url);
  }

  getapplicationnumbers(data): any {
    const url = this.baseURL + 'generateApplicationNumber?registrationType=' + data.registrationType + '&licenseNumber=' + data.licenseNumber+ '&liquorType=' + data.liquorType;
    return this.http.post(url, data).toPromise();
  }
  downloaddipbook(payload){
    const url = this.baseURL + 'reportReceipt/downloadReceipt'
    //  const url = 'http://localhost:3501/scmProduction/reportReceipt/downloadReceipt';
   return this.http.get(url,{ responseType: 'blob', params:payload });
 }

 getSugarMillCardCount(payload): Observable<any> {
  const url = this.baseURL + 'sugarMill/getStatusCount'
  return this.http.post(url,payload);
}

}