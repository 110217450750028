export class ExportTransitgatePassb12config {
    public static columns = [
      {
        name: 'id',
        displayName: 'wholesale.indentrequest.slno',
        type: 'number'
      },
      {
        name: 'draft_tp_date',
        displayName: 'Date',
        type: 'date',
        dateFormat: 'dd-MMM-yyyy',
      },
      {
        name: 'tp_reference_number',
        displayName: 'wholesale.transportpassgen.tranportpassnumber',
        type: 'string'
      },
      {
        name: 'from_entity_code',
        displayName: 'Name of Consignee',
        type: 'string'
      },
      {
        name: 'export_permit_number',
        displayName: 'Export Permit No',
        type: 'string'
      },
      {
        name: 'total_bl',
        displayName: 'Total Quantity(BL)',
        type: 'string',
        align:'center'
      },
      {
        name: 'status',
        displayName: 'wholesale.indentrequest.status',
        type: 'options',
        badgeColors: {
          SUBMITTED: 'badge-info',
          DRAFT: 'badge-warning',
          ACCEPTED: 'badge-warning',
          APPROVED: 'badge-success',
          CANCELLED: 'badge-danger',
          REJECTED: 'badge-danger',
          'REQUEST FOR CLARIFICATION': 'badge-warning',        
        }
      }, 
    ];
  }