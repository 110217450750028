export class PenaltyOnNonExOfIn {
    /*
    * Department Approval List ClassName Penaltyon Non Excetion Of Indent
    */
    id: number;
    transport_pass_id: number;
    tp_reference_number: String;
    unit_name: String;
    issue_date: Date;
    transfer_date: Date;
    requested_qty: number;
    requested_case: number;
    supplied_qty: number;
    supplied_case: number;
    indent_count: number;
    tp_status: String;

    /*
    * Department Approval List Class Name For Non Maintananceof Indent Register Penalty
    */

    fine_penalty_register_id: number;
    fine_method: String;
    fine_amount: number;
    entity_type_code: String;
    entity_name: String;
    license_type: String;
    transaction_number: String;
    transaction_posting_number: String;
    created_date: String;
    status: String;





    public static columns = [

        {
            name: 'id',
            displayName: 'brewery.common.s.no',
            type: 'number'
        },
        {
            name: 'tp_reference_number',
            displayName: 'brewery.transportpassgen.transportpassnumber',
            type: 'string'
        },
        {
            name: 'unit_name',
            displayName: 'language.unitname',
            type: 'string'
        },
        {
            name: 'issue_date',
            displayName: 'brewery.transportpassgen.issuedate',
            type: 'date',
        },
        {
            name: 'tp_status',
            displayName: 'brewery.common.status',
            // displayFcn: (item: any) => item.tp_status,
            type: 'string',
            badgeColors: {
                REQUEST_FOR_EXTENSION: 'badge-success'
            }
        }
    ];
    public static boxingaec = [
        {
            name: 'id',
            displayName: 'brewery.common.s.no',
            type: 'number'
        },
        {
            name: 'qr_start_time',
            displayName: 'brewery.qrcodeprinting.bottlingdate',
            type: 'string',
        },
        {
            name: 'bottling_plan_id',
            displayName: 'language.bottlingplanid',
            type: 'string',
        },
        {
            name: 'brand_value',
            displayName: 'language.brandname',
            type: 'string',
        },
        {
            name: 'no_of_cases',
            displayName: 'language.noofcases/monocartons',
            type: 'string',
        },
        {
            name: 'no_of_bottles',
            displayName: 'language.noofbottles',
            type: 'string',
        },
        {
            name: 'boxing_status',
            displayName: 'brewery.common.status',
            type: 'string',
        },
    ];

    /*
    * Department Approval List Column Name For Non Maintananceof Indent Register Penalty
    */
    public static nonMainOfIndRegPenalty = [
        {
            name: 'id',
            displayName: 'brewery.common.s.no',
            type: 'number'
        },
        {
            name: 'entity_type_code',
            displayName: 'language.unittype',
            type: 'string'
        },
        {
            name: 'entity_name',
            displayName: 'language.unitname',
            type: 'string'
        },
        {
            name: 'fine_method',
            displayName: 'language.finemethod',
            type: 'string'
        },
        {
            name: 'fine_amount',
            displayName: 'language.fineamount',
            type: 'string'
        },
        {
            name: 'status',
            displayName: 'brewery.common.status',
            type: 'string'
        },
    ];

    /*
    *  Brewery Fine Penalty(Non Maintenance Of Indent Details) List Column Name
    */
    public static NMOIDetails = [
        {
            name: 'id',
            displayName: 'S.No',
            type: 'number'
        },
        {
            name: 'entity_type_code',
            displayName: 'Unit Type',
            type: 'string'
        },
        {
            name: 'entity_name',
            displayName: 'Unit Name',
            type: 'string'
        },
        {
            name: 'fine_method',
            displayName: 'Fine Method',
            type: 'string'
        },
        {
            name: 'status',
            displayName: 'Status',
            type: 'string'
        },
        {
            name: 'fine_amount',
            displayName: 'Fine Amount ',
            type: 'string'
        }
    ]

    /*
    *  Indent Request Cancel Approval Department List Column Name
    */
    public static indentRequestCancel = [
        {
            name: 'id',
            displayName: 'S.No',
            type: 'number'
        },
        {
            name: 'indent_number',
            displayName: 'Indent NUmber',
            type: 'string'
        },
        {
            name: 'indent_date',
            displayName: 'Indent Date',
            type: 'string'
        },
        {
            name: 'total_cases',
            displayName: 'Totel No.of Cases/ Monocartons',
            type: 'string'
        },
        {
            name: 'total_bottles',
            displayName: 'Totel No.of Bottles',
            type: 'string'
        },
        {
            name: 'entity_type_code',
            displayName: 'Unit Type',
            type: 'string'
        },
        {
            name: 'to_entity_code',
            displayName: 'Unit Name',
            type: 'string'
        },
        {
            name: 'status',
            displayName: 'Status',
            type: "options",
            badgeColors: {
                'REQUEST_FOR_CANCELLATION': 'badge-success',
            }
        }
    ]
}




