export class Scheduledinspectionconfig {
    id: number;
    applicationNumber: string;
    dateofInspection: string;
    inspectionType: string;
    entity: number;
    licenseType: string;
    entityName: string;
    statusDesc: string;
    
    public static columns = [
        {
            name: 'id',
            displayName: 'language.s.no',
            type: 'number'
        },
        {
            name: "applicationNumber",
            displayName: 'language.appnum',
            type: "string",
        },
        {
            name: "inspectionDate",
            displayName: 'language.dateofInspection',
            type: "date",
            dateFormat: 'dd/MM/yyyy',
        },
        {
            name: 'inspectionTypeValue',
            displayName: 'language.typeofinspection',
            type: "string",
        },
        {
            name: "entity",
            displayName: "language.entity",
            type: "string",
        },
        {
            name: "licenseType",
            displayName: 'language.licensetype',
            type: "string",
        },
        
        {
            name: "stageDesc",  
            displayName: 'language.currentlywith',
            displayFcn: (item: any) => (item.status == 'APPROVED' || item.status == 'DRAFT' || item.status == 'SUBMITTED') ? '-' : item.stageDesc,
            type: 'objstring'
        },
        {
            name: 'statusDesc',
            displayName: 'language.status',
            type: 'options',
            badgeColors: {
                'Draft': 'badge-primary',
                'In Progress': 'badge-secondary',
                'Approved': 'badge-success',
                'Submitted': 'badge-info',
                'Rejected': 'badge-danger',
                'Clarification': 'badge-warning'
            }
        },
        {
            name: "forwardStatusDesc",
            displayName: 'Forward Status',
            type: 'options',
            badgeColors: {
                'Application Forward': 'badge-secondary',
            }
        }
    ]; 
}
export class Scheduledinspectionconfig1 {
    id: number;
    applicationNumber: string;
    dateofInspection: string;
    inspectionType: string;
    entity: number;
    licenseType: string; 
    statusDesc: string;
    
    public static columns = [
        {
            name: 'id',
            displayName: 'language.s.no',
            type: 'number'
        },
        {
            name: "applicationNumber",
            displayName: 'language.appnum',
            type: "string",
        },
        {
            name: "inspectionDate",
            displayName: 'language.dateofInspection',
            type: "date",
            dateFormat: 'dd/MM/yyyy',
        },
        {
            name: 'inspectionTypeValue',
            displayName: 'language.typeofinspection',
            type: "string",
        },
        {
            name: "entity",
            displayName: "language.entity",
            type: "string",
        },
        {
            name: "licenseType",
            displayName: 'language.licensetype',
            type: "string",
        },
        
        
        {
            name: 'statusDesc',
            displayName: 'language.status',
            type: 'options',
            badgeColors: {
                'Draft': 'badge-primary',
                'In Progress': 'badge-secondary',
                'Approved': 'badge-success',
                'Submitted': 'badge-info',
                'Rejected': 'badge-danger',
                'Clarification': 'badge-warning'
            }
        },
        {
            name: "forwardStatusDesc",
            displayName: 'Forward Status',
            type: 'options',
            badgeColors: {
                'Application Forward': 'badge-secondary',
            }
        },
    ]; 
}

// export class ScheduledInspection {
//     id: number;
//     applicationNumber: string;
//     dateofInspection: string;
//     inspectionType: string;
//     entity: number;
//     licenseType: string;
//     entityName: string;
//     statusDesc: string;
//   }
  export class EntityschInspconfig {

    public static columns = [
        {
            name: 'id',
            displayName: 'language.s.no',
            type: 'number'
        },
        {
            name: "entity",
            displayName: "language.entity",
            type: "string",
        },
        {
            name: "licenseType",
            displayName: 'language.licensetype',
            type: "string",
        },
        {
            name: "entityName",
            displayName: "language.entityname",
            type: "string",
        },
        {
            name: "address",
            displayName: "language.address",
            type: "string",
        },
    ];
}

export class EntityschedInsp {
    id: number;
    entity: number;
    licenseType: string;
    entityName: string;
    address: string;
  }

  export class EntityschInspconfig1 {

    public static columns = [

  
        {
            name: 'id',
            displayName: 'language.s.no',
            type: 'number'
        },
        {
            name: "licenseCategoryDesc",
            displayName: "language.entity",
            type: "string",
        },
        {
            name: "licenseType",
            displayName: 'language.licensetype',
            type: "string",
        },
        {
            name: "unitName",
            displayName: "language.entityname",
            type: "string",
        },
        {
            name: "street",
            displayName: "language.address",
            type: "string",
        },
    ];
}
export class EntityschedInsp1 {
    id: number;
    unitName: string;
    licenseType: string;
    licenseCategoryDesc: string;
    street: string;
  }
  export class EntityschInspconfig2 {

    public static columns = [
        {
            name: 'id',
            displayName: 'language.s.no',
            type: 'number'
        },   
        {
            name: "district",
            displayName: "District",
            type: "string",
        },
        {
            name: "field",
            displayName: 'Field',
            type: "string",
        },
        {
            name: "fieldOfficer",
            displayName: "Field Officer",
            type: "string",
        },
        {
            name: "designation",
            displayName: "Designation",
            type: "string",
        },
    ];
}
export class EntityschedInsp2 {
    id: number;
    district: string;
    field: string;
    fieldOfficer: string;
    designation: string;
  }

