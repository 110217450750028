export const BrewConst = {
    CUSTOMER: 'customer',
    OFFICER: 'officer',
    USER_TYPE: 'USER_TYPE',
    LOCALITY_URBAN: 'URBAN',
    LOCALITY_RURAL: 'RURAL',

    PREVIOUSYEAR_ITR: 'PREVIOUSYEAR',
    CURRENTYEAR_ITR: 'CURRENTYEAR',
    CONST_PROCESSING_FEE: 'PROCESSING_FEE',
    CONST_LICENSE_FEE: 'LICENSE_FEE',

    STATUS_DRAFT: 'DRAFT',
    STATUS_SAVE: 'SAVE',
    STATUS_SUBMITTED: 'SUBMITTED',
    STATUS_APPROVED: 'APPROVED',
    STATUS_REJECTED: 'REJECTED',
    STATUS_SENDBACK: 'SENDBACK',
    STATUS_IN_PROGRESS: 'IN_PROGRESS',

    EVENT_INITIATE: 'INITIATE',
    EVENT_SITE_VISIT_OFFICER_ASSIGMENT: 'SITE_VISIT_OFFICER_ASSIGMENT',
    EVENT_SENDBACK_SITE_INFO: 'SENDBACK_SITE_INFO',
    EVENT_PENDING_SITE_INFO: 'PENDING_SITE_INFO',
    EVENT_PENDING_DSC: 'PENDING_DSC',
    EVENT_LICENSE_RELEASE: 'LICENSE_RELEASE',
    EVENT_ASSIGMENT_FOR_SITE_VISIT_OFFICER: 'ASSIGMENT_FOR_SITE_VISIT_OFFICER',

    Module: 'BIO',
    ModuleCBW: 'CBW',
    BREWERY: 'BREWERY'
}