export class tankRegistrationconfig {

  // public static titleSection = {
  //   list: {
  //       title: 'master.district.list',
  //       button: {
  //           text: 'master.district.adddistrict',
  //           url: '/location/district/add'
  //       }
  //   },
  //   add: {
  //       title: 'master.district.list'
  //   },
  //   edit: {
  //       title: 'master.district.list'
  //   },
  //   view: {
  //       title: 'master.district.list'
  //   }
  // };
  public static breadcrumbs = [
    {
      text: 'language.sugarmill',
      url: '/sugarmill/tank/registration/list',
      hasHref: true
    },
    {
      text: 'language.tankregistration',
      url: '/sugarmill/tank/registration/list',
      hasHref: true
    }
  ];

  public static columns = [
    {
      name: 'id',
      displayName: 'language.s.no',
      type: 'number'
    },
    {
      name: 'applicationNumber',
      displayName: 'language.appnum',
      type: 'string'
    },
    {
      name: 'createdDate',
      displayName: 'Request Date & Time',
      type: 'date',
      dateFormat: 'dd-MMM-yyyy hh:mm a',
    },
    {
      name: 'tankId',
      displayName: 'language.tankid',
      type: 'string'
    },
    {
      name: 'tankName',
      displayName: 'language.tankname',
      type: 'string'
    },
    // {
    //   name: 'tankUsuageVlaue',
    //   displayName: 'language.tankusage',
    //   type: 'string'
    // },
    {
      name: 'tankTypeValue',
      displayName: 'language.tankusagetype',
      type: 'string'
    },
    {
      name: 'totalCapacity',
      displayName: 'language.totalCapacityqtl',
      type: 'string'
    },
    {
      name: "stageDesc",
      displayName: 'language.currentlywith',
      displayFcn: (item: any) => (item.status == 'APPROVED' || item.status == 'DRAFT' || item.status == 'SUBMITTED') ? '-' : item.stageDesc,
      type: 'objstring'
    },
    {
      name: "statusDesc",
      displayName: 'language.status',
      type: "options",
      badgeColors: {
        'Available': 'badge-primary',
        'In Progress': 'badge-secondary',
        'Approved': 'badge-success',
        'Submitted': 'badge-info',
        'Not Available': 'badge-danger',
        'Clarification': 'badge-warning',
        'Draft': 'badge-primary',
        'Dismantled': 'badge-danger',
        'Maintenance': 'badge-danger',
        'Rejected': 'badge-danger',
      }
    }

  ]
  public static columns1 = [
    {
      name: 'id',
      displayName: 'language.s.no',
      type: 'number'
    },
    {
      name: 'applicationNumber',
      displayName: 'language.appnum',
      type: 'string'
    },
    {
      name: 'createdDate',
      displayName: 'Request Date & Time',
      type: 'date',
      dateFormat: 'dd-MMM-yyyy hh:mm a',
    },
    {
      name: 'unitName',
      displayName: 'Sugar Mill Name',
      type: 'string'
    },
    {
      name: 'tankId',
      displayName: 'language.tankid',
      type: 'string'
    },
    {
      name: 'tankName',
      displayName: 'language.tankname',
      type: 'string'
    },
    // {
    //   name: 'tankUsuageVlaue',
    //   displayName: 'language.tankusage',
    //   type: 'string'
    // },
    {
      name: 'tankTypeValue',
      displayName: 'language.tankusagetype',
      type: 'string'
    },
    {
      name: 'totalCapacity',
      displayName: 'language.totalCapacityqtl',
      type: 'string'
    },
    {
      name: "stageDesc",
      displayName: 'language.currentlywith',
      displayFcn: (item: any) => (item.status == 'APPROVED' || item.status == 'DRAFT' || item.status == 'SUBMITTED') ? '-' : item.stageDesc,
      type: 'objstring'
    },
    {
      name: "statusDesc",
      displayName: 'language.status',
      type: "options",
      badgeColors: {
        'Available': 'badge-primary',
        'In Progress': 'badge-secondary',
        'Approved': 'badge-success',
        'Submitted': 'badge-info',
        'Not Available': 'badge-danger',
        'Clarification': 'badge-warning',
        'Draft': 'badge-primary',
        'Dismantled': 'badge-danger',
        'Maintenance': 'badge-danger',
        'Rejected': 'badge-danger',
      }
    }

  ]
}