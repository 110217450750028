export class TransitgatePassb10fl2config {
    public static columns = [
      {
        name: 'id',
        displayName: 'wholesale.indentrequest.slno',
        type: 'number'
      },
      
      {
        name: 'created_date',
        displayName: 'language.dateofapp',
        type: 'date'
      },
      {
        name: 'tp_reference_number',
        displayName: 'language.requestnumber',
        type: 'string'
      },
      {
        name: 'from_entity',
        displayName: 'brewery.transportpassgen.fromentity',
        type: 'string'
      },
      {
        name: 'to_entity',
        displayName: 'brewery.transportpassgen.toentity',
        displayFcn: (item: any) => item.to_entity+'/'+item.to_district_name,
        type: 'objstring'
      },
      {
        name: 'no_of_indent',
        displayName: 'brewery.transportpassgen.totalnoofindents',
        type: 'string'
      },
      {
        name: 'no_of_cases_requested',
        displayName: 'brewery.transportpassgen.totalnoofcases/monocartons',
        type: 'string',
        align:'center'

      },
      {
        name: 'status',
        displayName: 'wholesale.indentrequest.status',
        type: 'options',
        badgeColors: {
          SUBMITTED: 'badge-info',
          DRAFT: 'badge-warning',
          ACCEPTED: 'badge-success',
          APPROVED: 'badge-success',
          CANCELLED: 'badge-danger',
          REJECTED: 'badge-danger',
          'CLARIFICATION REQUESTED':'badge-warning',
          'WAITING FOR REQUEST CLARIFICATION': 'badge-primary',        
        }
      }
    ];
    public static columnsb10fl1 = [
      {
        name: 'id',
        displayName: 'wholesale.indentrequest.slno',
        type: 'number'
      },
      
      {
        name: 'created_date',
        displayName: 'language.dateofapp',
        type: 'date'
      },
      {
        name: 'tp_reference_number',
        displayName: 'language.requestnumber',
        type: 'string'
      },
      {
        name: 'from_entity',
        displayName: 'brewery.transportpassgen.fromentity',
        type: 'string'
      },
      {
        name: 'to_entity',
        displayName: 'brewery.transportpassgen.toentity',
        displayFcn: (item: any) => item.to_entity+'/'+item.to_district_name,
        type: 'objstring'
      },
      // {
      //   name: 'no_of_cases',
      //   displayName: 'language.noofcases',
      //   type: 'string'
      // },
      {
        name: 'no_of_cases_requested',
        displayName: 'Total No.of Cases/Monocartons Requested',
        type: 'string',
        align:'center'

      },
      {
        name: 'no_of_bottles_requested',
        displayName: 'No.of Bottles Requested',
        type: 'string',
        align:'center'

      },
      {
        name: 'status',
        displayName: 'wholesale.indentrequest.status',
        type: 'options',
        badgeColors: {
          SUBMITTED: 'badge-info',
          DRAFT: 'badge-warning',
          ACCEPTED: 'badge-success',
          APPROVED: 'badge-success',
          CANCELLED: 'badge-danger',
          REJECTED: 'badge-danger',
          'CLARIFICATION REQUESTED':'badge-warning',
          'WAITING FOR REQUEST CLARIFICATION': 'badge-primary',        
        }
      }
    ];
  }