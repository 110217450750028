import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { apiUrls } from '@appEnv/apiurls';
import * as fileSaver from "file-saver";

@Injectable({
  providedIn: 'root'
})
export class SalesService {
  constructor(
    private http: HttpClient
  ) { }
  baseURL = environment.localDBUrl;
  wholesaleNewURL = environment.wholesaleNewURL;

  // scannerdata/download/id

  scannerDataList(payload) {
    const url = this.wholesaleNewURL + 'scannerdata/getscannerdatalist';
    return this.http.post(url, payload);
  }
  downloadScannedData(id): Observable<any> {
    const url = this.wholesaleNewURL + 'scannerdata/download/' + id;
    return this.http.get(url);
  }
  download(param: any) {
    return this.http.get(param);
  }
  fileSaver(resp, documentName) {
    return fileSaver.saveAs(resp, documentName);
  }


  get(): Observable<any> {
    const url = this.baseURL + apiUrls.addSalesLedger;
    return this.http.get(url);
  }

  addSalesLedger(postParam): Observable<any> {
    const url = this.baseURL + apiUrls.addSalesLedger;
    return this.http.post(url, postParam);
  }

  getSalesLedgerId(id): Observable<any> {
    const url = this.baseURL + apiUrls.addSalesLedger + '/' + id;
    return this.http.get(url);
  }
  validateitem(obj): Observable<any> {
    const url = this.wholesaleNewURL + 'retail/validateitem';
    return this.http.post(url, obj)
  }
  savesalesledata(obj): Observable<any> {
    const url = this.wholesaleNewURL + 'retailbill/savebill';
    return this.http.post(url, obj)
  }

  SubmitReturns(obj): Observable<any> {
    const url = this.wholesaleNewURL + 'retailbill/returnbill';
    return this.http.post(url, obj)
  }


  search(obj): Observable<any> {
    const url = this.wholesaleNewURL + 'customdata/getdata';
    return this.http.post(url, obj)
  }
}
