import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '@appEnv/environment';
import { apiUrls } from '@appEnv/apiurls';

@Injectable({
  providedIn: 'root'
})
export class ImportPermitService {

  constructor(private http: HttpClient) { }
  baseURL = environment.parentUnit;
    devisionUrl = environment.devisionUrl;
    licenseURL = environment.licneseBaseUrl;
    masterURL = environment.devisionUrl;
    domainUrl = environment.domainUrl;

    spiritImportPermitLazySearch(payload):Observable<any>{
      const url = this.baseURL + 'spiritImportPermit/lazySearch';
      return this.http.post(url,payload);
    }
    addSpiritOrderPermit(payload):Observable<any>{
      const url = this.baseURL + 'SIPOrderProcessing';
      return this.http.post(url,payload);
    }
    updateSipRouteDetails(payload):Observable<any>{
      const url = this.baseURL + 'SIPOrderProcessing/updateSipRouteDetails';
      //const url = 'http://localhost:3501/scmProduction/SIPOrderProcessing/updateSipRouteDetails';
      return this.http.post(url,payload);
    }
    spiritOrderPermitLazySearch(payload):Observable<any>{
      const url = this.baseURL + 'SIPOrderProcessing/lazySearch';
      return this.http.post(url,payload);
    }
    spiritOrderPermitGetById(id):Observable<any>{
      const url = this.baseURL + 'SIPOrderProcessing?id=' +id;
      return this.http.get(url);
    }
    findpaymentdetailsSpiritImportOrderPermit(applicationno, feetype): Observable<any> {
      let url = this.baseURL + 'SIPOrderProcessing/findPaymentDetails?applicationNumber=' + applicationno + '&feeType=' + feetype;
      return this.http.get(url);
    }
    licenceAdd(payload): Observable<any> {
      const url = this.domainUrl + 'payandrecon/1.0/license/multipleadd';
      return this.http.post(url, payload);
    }
    saveupdatepaymentdetails(payload): Observable<any> {
      const url = this.baseURL + 'SIPOrderProcessing/saveUpdatePaymentDetails';
      return this.http.post(url, payload);
    }
    downloadDocument(appnumber, liceanseNumber, feeType): any {
      const url = this.baseURL + 'reportReceipt/downloadPaymentReceipt' +
        '?applicationNumber=' + appnumber +
        '&licenseNumber=' + liceanseNumber +
        '&feeType=' + feeType + '&receiptType=SIP_ORDER_PROCESS';
  
      return this.http.get(url, { responseType: 'blob' });
    }
    spiritImportPermitDropdown(payload):Observable<any> {
      const url = this.baseURL + 'spiritImportPermit/getDropDown';
      return this.http.post(url, payload);
    }

    getentitytype(type):Observable<any>{
      const url = this.licenseURL + 'license/category/'+type;
      return this.http.get(url);
    }  
}
