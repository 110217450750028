import * as moment from "moment";
export class fl36config {
  public static columns = [
    {
      name: 'id',
      displayName: 'brewery.common.s.no',
      type: 'number'
    },
    {
      name: 'tp_reference_number',
      displayName: 'brewery.transportpassgen.transportpassnumber',
      type: 'string'
    },
    {
      name: 'date',
      displayName: 'brewery.transportpassgen.issuedate',
      displayFcn: (item: any) => moment(item.created_date).format("DD-MM-YYYY"),
      type: 'objstring'
      // dateFormat: 'dd-MMM-yyyy',
    },
    {
      name: 'created_date',
      displayName: 'brewery.transportpassgen.transportpassdate',
      type: 'date'
    },
    
    {
      name: 'no_of_indent',
      displayName: 'brewery.transportpassgen.totalnoofindents',
      type: 'string',
      align:"center"
    },
    {
      name: 'from_entity',
      displayName: 'brewery.transportpassgen.fromentity',
      type: 'string'
    },
    {
      //name: 'to_entity',
      displayName: 'brewery.transportpassgen.toentity',
      displayFcn: (item: any) => item.to_entity+'/'+item.to_district_name,
      type: 'objstring'
    },
    {
      name: 'no_of_cases_requested',
      displayName: 'brewery.transportpassgen.noofcasesreq',
      type: 'string',
      align: 'center'
    },
    {
      name: 'no_of_bottles_requested',
      displayName: 'brewery.transportpassgen.noofbottlesreq',
      type: 'string',
      align: 'center'
    },
    {
      name: 'no_of_cases_dispatached',
      displayName: 'brewery.transportpassgen.noofcasesdispatched',
      type: 'string',
      align: 'center'
    },
    {
      name: 'no_of_bottles_dispatached',
      displayName: 'brewery.transportpassgen.noofbottlesdispatched',
      type: 'string',
      align: 'center'
    },
    // {
    //   name: 'valid_upto',
    //   displayName: 'Valid Upto',
    //   type: 'string',
    //   align: 'center',
    //   dateFormat: 'dd-MMM-yyyy',
    // },
    //   {
    //     name: 'unit_name',
    //     displayName: 'Unit Name',
    //     type: 'string',
    //     align:'center'
    //   }, 
    //   {
    //     name: 'unit_name',
    //     displayName: 'Valid Till',
    //     type: 'string',
    //     align:'center'
    //   },
    {
      name: 'status',
      displayName: 'brewery.common.status',
      type: 'options',
      badgeColors: {
        // SUBMITTED: 'badge-info',
        // DRAFT: 'badge-warning',
        // ACCEPTED: 'badge-success',
         APPROVED: 'badge-success',
        // CANCELLED: 'badge-danger',
        REJECTED: 'badge-danger',
        // 'WAITING FOR REQUEST CLARIFICATION': 'badge-primary',
        'REQUEST FOR CLARIFICATION' : 'badge-warning',
        FL36_DRAFT_TP_SUBMITTED: 'badge-info',
        FL36_DRAFT_TP_APPROVED: 'badge-success',
        FL36_FINAL_TP_SUBMITTED: 'badge-info',
        FL36_FINAL_TP_APPROVED: 'badge-success',
        FL36_DRAFT_TP_CLARIFICATION_REQUESTED: 'badge-primary',
        DRAFT_TP_CLARIFICATION_REQUESTED: 'badge-primary',
        FL36_FINAL_TP_CLARIFICATION_REQUESTED: 'badge-primary',
        FL36_DRAFT_TP_REQUESTFORCLARIFICATION:'badge-warning',
        DRAFT_TP_APPROVED: 'badge-warning',
        FL36_DRAFT_TP_REJECTED:'badge-danger',
        FL36_FINAL_TP_REJECTED :'badge-danger',
        FL36_FINAL_TP_REQUESTFORCLARIFICATION:'badge-warning',
        SUBMITTED: 'badge-info',
        CANCELLED: 'badge-danger'
      }
    },
    {
      name: 'nack_status',
      displayName: 'brewery.transportpassgen.ackstatus',
      displayFcn: (item: any) => item.ack_status == "RECEIVED" ? item="ACKNOWLEDGED" : item="NOT ACKNOWLEDGED",
      type: 'objstring',
      align: 'center'
    },
  ];
}