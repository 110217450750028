export class Labelrequestplanconfig {

    public static columns = [
        {
            name: 'id',
            displayName:'districtwarehouse.orderpickup.sno',
            type: 'number'
        },
        // here we need to change the display name  once api i sredy
        {
            name: 'tp_date',
            displayName: 'importunitbio.labelrequestplan.applicationdate',
            displayFcn: (item: any) => item.application_date,
            type: 'objstring',
            //dateFormat: 'dd-MMM-yyyy',
        },
        // here we need to change the display name once api i sredy
        {
            name: 'tp_no',
            displayName: 'importunitbio.labelrequestplan.applicationno',
            displayFcn: (item: any) => item.application_number,
            type: 'objstring'
        },

        {
            name: 'transportpassno',
            displayName: 'importunitbio.labelrequestplan.transportpassno',
            displayFcn: (item: any) => item.transport_pass_number,
            type: 'objstring'
        },
        {
            name: 'labelrequest',
            displayName: 'importunitbio.labelrequestplan.labelreqapplicationno',
            displayFcn: (item: any) => item.bottling_plan_id,
            type: 'objstring',
            align: 'center'
        },
        {
            name: 'entityname',
            displayName: 'importunitbio.labelrequestplan.entityname',
            displayFcn: (item: any) => item.entity_name,
            type: 'objstring',
            align: 'center'
        },
        {
            name: 'noofcases/monocartons',
            displayName: 'importunitbio.labelrequestplan.noofcases/monocartons',
            displayFcn: (item: any) => item.number_of_cases_monocartons,
            type: 'objstring'
        },
        {
            name: 'processstatus',
            displayName: 'importunitbio.labelrequestplan.processstatus',
            displayFcn: (item: any) => item.process_status,
            type: 'objstring'
        },
        {
            name: 'status',
            displayName: 'importunitbio.labelrequestplan.status',
            displayFcn: (item: any) => item.status,
            type: 'objstring'
        }
    ];
}
