import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '@appEnv/environment';
import { apiUrls } from '@appEnv/apiurls'

@Injectable({
    providedIn: 'root'
})

export class InternalAuditService {

    baseURL = environment.parentUnit;
    masterURL = environment.devisionUrl;

    licenseURL = environment.licneseBaseUrl;
    userURL=environment.userManagerUrl
    constructor(private http: HttpClient) { }

    getAuditPlanById(id): Observable<any> {
        const url = this.baseURL + apiUrls.getAuditPlanById + id;
        return this.http.get(url);
    }
    addAuditplan(postdata): Observable<any> {
        const url = this.baseURL + apiUrls.addAuditPlan;
        return this.http.post(url, postdata);
    }
    searchAuditplan(postdata): Observable<any> {
        const url = this.baseURL + apiUrls.auditplanSearch;
        return this.http.post(url, postdata);
    }
    getapplicationnumbers(data): Observable<any> {
        const url = this.baseURL + 'generateApplicationNumber?registrationType= ' + data;
        return this.http.post(url, data);
    }
    getExciseOfficeTypeMaster() {
        const url = this.masterURL + "api/masterValues/findDropDownMaster?dropDownKey=EXCISE_OFFICE";
        return this.http.get(url)
    }
    getExciseOfficeNameMaster() {
        const url = this.masterURL + "api/masterValues/findDropDownMaster?dropDownKey=EXCISE_OFFICE_ROLE";
        return this.http.get(url)
    }
    getPersonnelsInvolvedAuditTeam() {
        const url = this.masterURL + "api/masterValues/findDropDownMaster?dropDownKey=PERSON_INVOLVED_AUDIT";
        return this.http.get(url)
    }

    getChecklistDetailsMaster() {
        const url = this.masterURL + "api/masterValues/findDropDownMaster?dropDownKey=CHECKLIST_DETAILS";
        return this.http.get(url)
    }
    getChecklistAnswerTypeMaster() {
        const url = this.masterURL + "api/masterValues/findDropDownMaster?dropDownKey=CHECKLIST_QA";
        return this.http.get(url)
    }
    getChiefAuditorMaster(key) {
        const url = this.userURL + "user/getUserByDesignationCode?code="+key;
        return this.http.get(url)
    }
    getPenaltyStatus() {
        const url = this.masterURL + "api/masterValues/findDropDownMaster?dropDownKey=AUDIT_STATUS";
        return this.http.get(url)
    }

    getdetails(entity): any {//work
        const url = this.licenseURL + 'license/category/' + entity;
        return this.http.get(url).toPromise();
      }

    getLicDetails(entity): Observable<any> {
        const url = this.licenseURL + 'license/category/' + entity + '?optionalCategory=' + entity;
        return this.http.get(url);
    }

    getdetails2(entity): Observable<any> {
        const url = this.licenseURL + 'license/category/' + entity+ '?optionalCategory=' + entity;;
        return this.http.get(url);
    }

    getUnitDetails(entity,licNum):Observable<any> {
        const url = this.licenseURL + 'license/category/' + entity+ '?licenseNumber=' + licNum;
        return this.http.get(url);
    }
    downloadenavalue(payload){
        const url = this.baseURL + 'reportReceipt/downloadReceipt'
        return this.http.get(url,{ responseType: 'blob', params:payload });
      }
      getPdCardCount(payload): Observable<any> {
        const url = this.baseURL + 'pd/getStatusCount'
        return this.http.post(url,payload);
      }

}
