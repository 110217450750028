export class Mstlist {
   id:Number;
   reference_number:String;
   inspection_type:String;
   license_type:String;
   status:String;
   verification_for_month:String;
   verification_for_year:String;
   performed_by:String;


   public static columns=[
      {
         name: 'id',
         displayName: 'S.No',
         type: 'number'
      },
      {
         name: 'reference_number',
         displayName: 'Stock Code',
         type: 'string'
      },
      {
         name: 'inspection_type',
         displayName: 'Licence Type',
         type: 'string'
      },
      {
         name: 'status',
         displayName: 'Status',
         type: 'options',
         badgeColors: {
            SUBMITTED: 'badge-info',
            DRAFT: 'badge-warning',
            ACCEPTED: 'badge-warning',
            APPROVED: 'badge-success',
            CANCELLED: 'badge-danger',
            REJECTED: 'badge-danger'
            
          }
      },
      {
         name: 'verification_for_month + "/" + verification_for_year',
         displayName: 'Stock Month_Year',
         type: 'string'
      },
      {
         name: 'inspection_date',
         displayName: 'Inspection Date',
         type: 'string'
      },
      {
         name: 'license_number',
         displayName: 'Licence Number',
         type: 'string'
      },
      {
         name: 'performed_by',
         displayName: 'Performed By',
         type: 'string'
      },

   ]
   }

  