import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '@appEnv/environment';
import { apiUrls } from '@appEnv/apiurls';

@Injectable({
  providedIn: 'root'
})
export class TankregistartionServiceBrew {
 
  baseURL = environment.parentUnit;
  constructor(private http: HttpClient) { }

  getvessaltypes(): Observable<any> {
    const url = this.baseURL + apiUrls.vesseltype;
    return this.http.get(url);

  }
  getTanRegById(id): Observable<any> {
    const url = this.baseURL + 'breweryTankRegistration?id='+ id;
    return this.http.get(url, id);
  }
  getTanOverviewRegById(id): Observable<any> {
    const url = this.baseURL + 'breweryTankRegistration/overview/'+ id;
    return this.http.get(url, id);
  }
  addTanReg(postdata): Observable<any> {
    const url = this.baseURL + 'breweryTankRegistration';
    return this.http.post(url, postdata);
  }
  approveTanReg(postdata): Observable<any> {
    // /breweryTankRegistration
    const url = this.baseURL + apiUrls.addTankReg;
    return this.http.post(url, postdata);
  }
  updateTanReg(postdata): Observable<any> {
    const url = this.baseURL + apiUrls.addTankReg;
    return this.http.post(url, postdata);
  }
  searchTanReg(postdata): Observable<any> {
    const url = this.baseURL + 'breweryTankRegistration/tankRegistrationLazySearch';
    return this.http.post(url, postdata);
  }
  searchTanRegOveriew(postdata): Observable<any> {
    const url = this.baseURL + 'breweryTankRegistration/overview/search';
    return this.http.post(url, postdata);
  }
    

  getapplicationnumbers(data): any {
    const url = this.baseURL + 'generateApplicationNumber?registrationType=' + data.registrationType + '&licenseNumber=' + data.licenseNumber+ '&liquorType=' + data.liquorType;
    return this.http.post(url, data).toPromise();
  }

  getlabledata(): Observable<any> {
    const url = this.baseURL + 'breweryTankRegistration/overview/tankStatusCount';
    return this.http.get(url);
  }

  gettankviewlist(data, listtype): Observable<any> {
    const url = this.baseURL + apiUrls.gettankviewlist + listtype;
    return this.http.post(url, data);
  }
  getTanRegBytankId(id): Observable<any> {
    const url = this.baseURL + 'tankRegistration/getTankRegByTankId?tankId='+ id;
    return this.http.get(url, id);
  }
  gettankIdList(data): Observable<any> {
    const url = this.baseURL + 'findTankRegistration/dropDown';
    return this.http.post(url, data);
  }
  getTankDetailByName(paylod): Observable<any> {
    const url = this.baseURL + 'transferOfBeer/tankQuantity';
    return this.http.post(url, paylod);
  }
  getStatusCount(entityType?, isDrumRegTanktype?): Observable<any> {
    const url = this.baseURL + 'breweryTankRegistration/getStatusCount?entityType='+entityType+'&isDrumRegTanktype='+isDrumRegTanktype;
    return this.http.post(url,entityType,isDrumRegTanktype);
  }
  downloadinspection(payload){
    const url = this.baseURL + 'reportReceipt/downloadReceipt'
      // const url = 'http://localhost:3501/scmProduction/reportReceipt/downloadReceipt';
    return this.http.get(url,{ responseType: 'blob', params:payload });
  }
}
