export class StockInHand {

    public static columns = [
        {
            name: 'id',
            displayName: 'wholesale.common.sno',
            type: 'number'
        },        
        {
            name: 'brand_name',
            displayName: 'wholesale.stockinhand.brandname',
            displayFcn: (item: any) => item.brand_name,
            type: 'objstring'
        },
        {
            name: 'liquor_type_name',
            displayName: 'wholesale.stockinhand.liquortype',
             displayFcn: (item: any) => item.liquor_type_name,
            type: 'objstring'
        },
        {
            name: 'sub_liquor_type_name',
            displayName: 'wholesale.stockinhand.subliquortype',
            displayFcn: (item: any) => item.sub_liquor_type_name,
            type: 'objstring'
        },
        {
            name: 'liquor_description',
            displayName: 'wholesale.stockinhand.liquordetaileddescription',
            displayFcn: (item: any) => item.liquor_description,
            type: 'objstring'
        },
        {
            name: 'package_size',
            displayName: 'wholesale.stockinhand.packagingsize',
            // displayFcn: (item: any) =>item.package_size ? item.package_size.concat(" ML") : "0",
            displayFcn: (item: any) =>item.package_size ? item.package_size + " ML" : "0",
            type: 'objstring'
        },
        {
            name: 'package_type_name',
            displayName: 'wholesale.stockinhand.packagetype',
            displayFcn: (item: any) => item.package_type_name,
            type: 'objstring'
        },
        {
            name: 'package_count',
            displayName: 'No. of Bottles per Case',
            displayFcn: (item: any) => item.package_count,
            type: 'objstring'
        },
        {
            name: 'available_cases',
            displayName: 'Available Cases/Monocartons',
            displayFcn: (item: any) => parseInt(item.available_cases),
            type: 'objstring'
        },
        {
            name: 'available_qty',
            displayName: 'Available Bottles',
            displayFcn: (item: any) => parseInt(item.available_qty),
            type: 'objstring'
        },
       
        
    ];

    public static retailHBRColumns = [
        {
            name: 'id',
            displayName: 'wholesale.common.sno',
            type: 'number'
        },        
        {
            name: 'brand_name',
            displayName: 'wholesale.stockinhand.brandname',
            displayFcn: (item: any) => item.brand_name,
            type: 'objstring'
        },
        {
            name: 'liquor_type_name',
            displayName: 'wholesale.stockinhand.liquortype',
             displayFcn: (item: any) => item.liquor_type_name,
            type: 'objstring'
        },
        {
            name: 'sub_liquor_type_name',
            displayName: 'wholesale.stockinhand.subliquortype',
            displayFcn: (item: any) => item.sub_liquor_type_name,
            type: 'objstring'
        },
        {
            name: 'liquor_description',
            displayName: 'wholesale.stockinhand.liquordetaileddescription',
            displayFcn: (item: any) => item.liquor_description,
            type: 'objstring'
        },
        {
            name: 'package_size',
            displayName: 'wholesale.stockinhand.packagingsize',
            // displayFcn: (item: any) =>item.package_size ? item.package_size.concat(" ML") : "0",
            displayFcn: (item: any) =>item.package_size ? item.package_size + " ML" : "0",
            type: 'objstring'
        },
        {
            name: 'package_type_name',
            displayName: 'wholesale.stockinhand.packagetype',
            displayFcn: (item: any) => item.package_type_name,
            type: 'objstring'
        },
        {
            name: 'package_count',
            displayName: 'No. of Bottles per Case',
            displayFcn: (item: any) => item.package_count,
            type: 'objstring'
        },
        /* {
            name: 'available_cases',
            displayName: 'Available Cases/Monocartons',
            displayFcn: (item: any) => parseInt(item.available_cases),
            type: 'objstring'
        }, */
        {
            name: 'available_qty',
            displayName: 'Available Bottles',
            displayFcn: (item: any) => parseInt(item.available_qty),
            type: 'objstring'
        },
       
        
    ];
}
