import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '@appEnv/environment';
import { apiUrls } from '@appEnv/apiurls';
@Injectable({
  providedIn: 'root'
})
export class DipBookBrewService {

  constructor(
    private http: HttpClient
  ) { }
  baseURL = environment.parentUnit;
  devisionUrl = environment.devisionUrl;
  licenseURL = environment.licneseBaseUrl;

  searchDipbook(postParam): Observable<any> {
    const url = this.baseURL + 'dipBook/lazySearch';
    return this.http.post(url, postParam);
  }

  getStatusCount(entityType?): Observable<any> {
    const url = this.baseURL + 'dipBook/getStatusCount?entityType=' + entityType;
    return this.http.get(url);
  }
  
  getStatusCount1(entityType?, createdBy?): Observable<any> {
    const url = this.baseURL + 'planPreDeclaration/getStatusCount?entityType=' + entityType +'&createdBy=' + createdBy;
    return this.http.get(url);
  }

  searchDipbook1(postParam): Observable<any> {
    const url = this.baseURL + 'planPreDeclaration/search';
    return this.http.post(url, postParam);
  }

  findDipbookId(id): Observable<any> {
    const url = this.baseURL + 'dipBook/' + id;
    return this.http.get(url);
  }
  findDipbookId1(id): Observable<any> {
    const url = this.baseURL + 'planPreDeclaration/' + id;
    return this.http.get(url);
  }
  findDipbookId2(id): any {
    const url = this.baseURL + 'planPreDeclaration/' + id;
    return this.http.get(url).toPromise();
  }

  downloadrepair(payload){
    const url = this.baseURL + 'reportReceipt/downloadReceipt';
  return this.http.get(url,{ responseType: 'blob', params:payload });
}

  adddDipBookDetails(data): Observable<any> {
    const url = this.baseURL + 'dipBook';
    return this.http.post(url, data);
  }

  adddDipBookDetails1(data): Observable<any> {
    const url = this.baseURL + 'planPreDeclaration';
    return this.http.post(url, data);
  }
  dipBookSearch(data): Observable<any> {
    const url = this.baseURL + 'dipBook/lazySearch';
    return this.http.post(url, data);
  }
  updateWorkFlow(payload): Observable<any> {
    const url = this.baseURL + 'dipBook/updateWorkFlow';
    return this.http.post(url, payload);
  }

  getById(id): Observable<any> {
    const url = this.baseURL + 'dipBook/' + id;
    return this.http.get(url);
  }


  getapplicationnumbers(data): any {
    const url = this.baseURL + 'generateApplicationNumber?registrationType=' + data.registrationType + '&licenseNumber=' + data.licenseNumber + '&liquorType=' + data.liquorType;
    return this.http.post(url, data).toPromise();
  }
  getDropdownByKey(key, addi?, add2?): Observable<any> {

    let url = this.baseURL + apiUrls.getDropDownBykey + '?dropDownKey=' + key;
    url += (addi) ? '&additionalFilter2=' + addi : '';
    if (add2) {
      url += (add2) ? '&additionalFilter1=' + add2 : '';
    }
    return this.http.get(url);
  }

  getCompletedBottlingPlanList(payload):Observable<any>{
   const url =  this.baseURL + 'bottlingProductionPlan/bottlingPlanDropDown';
   return this.http.post(url,payload);
  }
  
  getApprovedBatchNoList(payload):Observable<any>{
    const url = this.baseURL + 'planPreDeclaration/search';
    return this.http.post(url,payload);
  }

  bottlingPlanLazySearch(payload): Observable<any> {
    const url = this.baseURL + apiUrls.bottlingsearch;
    return this.http.post(url, payload);
  }

  downloaddipbook(payload){
    const url = this.baseURL + 'reportReceipt/downloadReceipt'
    //  const url = 'http://localhost:3501/scmProduction/reportReceipt/downloadReceipt';
    return this.http.get(url,{ responseType: 'blob', params:payload });
  }
  getPdCardCount(payload): Observable<any> {
    const url = this.baseURL + 'pd/getStatusCount'
    return this.http.post(url,payload);
  }
}