export class entityPopupModel {


  id: number;
  unitName: string;
  licenseSubCategory: string;
  licenseNumber: number;
  licenseExpiryDate: string;
  districtDesc: string;


}

export class SampleRequest {
  id: number;
  dateofApplication: string;
  applicationNumber: string;
  labName: string;
  noOfSamplePrepared: number;
  productType: string;
  status: string;
}

export class FieldOfficer {
  id: number;
  //disctrict: string;
  districtCode: string;
  fieldorsector: string;
  exciseofficialName: string;
  designation: string;
}


export class reportEvidanceExcise {
  id: number;
  createdDate: string;
  applicationNumber: string;
  complainType: string;
  districtCode: number;
  officerName: string;
  designation: string;
  status: string;
}

export class officerEntity {
  id: number;
  districtOfficer: string;
  fieldsector: string;
  name_of_field_Officer_excise: string;
  designation: string;
}
