export class exportunitregistrationconfig {


    public static exportunit = [
        {
            name: 'id',
            displayName: 'wholesale.indentrequest.slno',
            //displayFcn: (item: any) => item.sr_no,
            type: 'number'
          },
        {
            name: 'referenceno',
            displayName:'brewery.exportunitregistration.referenceno',
            displayFcn: (item: any) => item.ref_number,
            type: 'objstring'
        },
        {
            name: 'breweryname',
            displayName:'brewery.exportunitregistration.breweryname',
            displayFcn: (item: any) => item.entity_name,
            type: 'objstring'
        },
        // here we need to change the display name  once api i sredy
        {
            name: 'importerexportercode',
            displayName: 'brewery.exportunitregistration.importerexportercode',
            displayFcn: (item: any) => item.importer_exporter_code,
            type: 'objstring'
        },
        // here we need to change the display name once api i sredy
        {
            name: 'registrationcummember',
            displayName: 'brewery.exportunitregistration.registrationcummember',
            displayFcn: (item: any) => item.registration_membership_no,
            type: 'objstring'
        },

        {
            name: 'starexporthouseno',
            displayName: 'brewery.exportunitregistration.starexporthouseno',
            displayFcn: (item: any) => item.export_house_no,
            type: 'objstring'
        },
        {
            name: 'gstinNo',
            displayName: 'brewery.exportunitregistration.gstinNo',
           displayFcn: (item: any) => item.gstin_no,
            type: 'objstring',
            align: 'center'
        },
        // {
        //     name: 'currentlyWith',
        //     displayName: 'brewery.exportunitregistration.currentlyWith',
        //    displayFcn: (item: any) => item.forward_desg,
        //     type: 'objstring',
        //     align: 'center'
        // },
        {
            name: 'status',
            displayName: 'brewery.common.status',
             displayFcn: (item: any) => item.status,
             type: 'options',
             align: 'center',
             badgeColors: {
                'APPROVED': 'badge-success',
                'SUBMITTED': 'badge-info',
                'REJECTED': 'badge-danger',
                'CLARIFICATION REQUESTED': 'badge-warning'
              }
        },
       
    ];
 
}