export class Importpermitacceptance {
  public static columns = [
    {
      name: 'id',
      displayName: 'S.No.',
      type: 'number'
    },

    {
      name: 'indent_number',
      displayName: 'Import Permit Number',
      type: 'string'
    },
    {
      name: 'created_date',
      displayName: 'Permit Date',
      type: 'date'
    },
    {
      name: 'total_cases',
      displayName: 'Total No. of Cases/Monocartons',
      type: 'string'
    },
    {
      name: 'total_bottles',
      displayName: 'Total No. of Bottles',
      type: 'string'
    },
    {
      name: 'from_entity_type_code',
      displayName: 'Unit Type',
      type: 'string',
      align: 'center'
    },
    {
      name: 'from_entity_code',
      displayName: 'Unit Name',
      type: 'string',
      align: 'center'
    },

    {
      name: 'status',
      displayName: 'Status',
      type: 'options',
      badgeColors: {
        SUBMITTED: 'badge-info',
        DRAFT: 'badge-warning',
        ACCEPTED: 'badge-warning',
        APPROVED: 'badge-success',
        CANCELLED: 'badge-danger',
        REJECTED: 'badge-danger',
        'WAITING FOR REQUEST CLARIFICATION': 'badge-secondary',
        TP_ACKNOWLEDGED: 'badge-primary'
      }
    },

  ];
  public static csdacceptance = [
    {
      name: 'id',
      displayName: 'S.No.',
      type: 'number'
    },

    {
      name: 'indent_number',
      displayName: 'CSD Permit Number',
      type: 'string'
    },
    {
      name: 'created_date',
      displayName: 'CSD Permit Date',
      type: 'date'
    },
    {
      name: 'total_cases',
      displayName: 'Total No. of Cases/Monocartons',
      type: 'string'
    },
    {
      name: 'total_bottles',
      displayName: 'Total No. of Bottles',
      type: 'string'
    },
    {
      name: 'from_entity_type_code',
      displayName: 'Unit Type',
      type: 'string',
      align: 'center'
    },
    {
      name: 'from_entity_code',
      displayName: 'Unit Name',
      type: 'string',
      align: 'center'
    },

    {
      name: 'status',
      displayName: 'Status',
      type: 'options',
      badgeColors: {
        SUBMITTED: 'badge-info',
        DRAFT: 'badge-warning',
        ACCEPTED: 'badge-warning',
        APPROVED: 'badge-success',
        CANCELLED: 'badge-danger',
        REJECTED: 'badge-danger',
        'WAITING FOR REQUEST CLARIFICATION': 'badge-secondary',
        TP_ACKNOWLEDGED: 'badge-primary'
      }
    },

  ];
}
