import * as moment from "moment";

export class SalesreportConfig {
    public static Datewisereportcolumns = [
        {
            name: 'bill_date',
            displayName: 'retailpos.salesreport.date',
            displayFcn: (item: any) => moment(item.bill_date).format("DD-MM-YYYY HH:mm"),
            type: 'objstring'
        },
        {
            name: 'bill_number',
            displayName: 'retailpos.salesreport.billno',
            displayFcn: (item: any) => item.bill_number,
            type: 'objstring'
        },
        {
            name: 'total_amount',
            displayName: 'retailpos.salesreport.total',
            displayFcn: (item: any) => item.total_amount,
            type: 'objstring'
        }
    ];
    public static Brandwisereportcolumns = [
        {
            name: 'bill_date',
            displayName: 'retailpos.salesreport.date',
            displayFcn: (item: any) => moment(item.bill_date).format("DD-MM-YYYY HH:mm"),
            type: 'objstring'
        },
        {
            name: 'brand_name',
            displayName: 'retailpos.salesreport.brandname',
            displayFcn: (item: any) => item.brand_name,
            type: 'objstring'
        },
        {
            name: 'total_qty',
            displayName: 'retailpos.salesreport.qty',
            displayFcn: (item: any) => item.total_qty,
            type: 'objstring'
        },
        {
            name: 'total_amount',
            displayName: 'retailpos.salesreport.amount',
            displayFcn: (item: any) => item.total_amount,
            type: 'objstring'
        },
    ];
  
    public static columnsReport = [
        {
            name: 'id',
            displayName: 'S No',
            type: 'number'
        },
        {
            name: 'dispatchDatee',
            displayName: 'Disaptch Date',
            type: 'date'
        },
        {
            name: 'transportPassNoe',
            displayName: 'Transport Pass No',
            type: 'string'
        },
        {
            name: 'unitNamee',
            displayName: 'Unit Name',
            type: 'string'
        },
        {
            name: 'totScannedCasese',
            displayName: 'Cases Dispatched',
            type: 'string'
        },
        {
            name: 'totScannedBottlese',
            displayName: 'Bottles Dispatched',
            type: 'string'
        },

    ];
    public static Salesofl11columns = [
        {
            name: 'bill_date',
            displayName: 'Event Date',
            displayFcn: (item: any) => item.bill_date,
            type: 'date'
        },
        {
            name: 'bill_number',
            displayName: 'Licence No',
            displayFcn: (item: any) => item.bill_number,
            type: 'objstring'
        },
        {
            name: 'total_amount',
            displayName: 'Quantity',
            displayFcn: (item: any) => item.total_amount,
            type: 'number'
        },
    ];
}