import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '@appEnv/environment';
import { apiUrls } from '@appEnv/apiurls'



@Injectable({
    providedIn: 'root'
  })
  export class CaseCreationService{
    baseURL = environment.parentUnit;
    scmothermodule = environment.scmOtherModules;
    constructor(private http: HttpClient) { }
    getAllCaseCreation(postdata): Observable<any> {
      const url = this.scmothermodule + 'CaseCreationController/lazySearch';
      return this.http.post(url,postdata);
    }
    getApplicationNumber():Observable<any>{
      const url = this.scmothermodule + 'CaseCreationController/getFieldOfficerApplication';
      return this.http.get(url)
    }
    getAllMf7Details(postdata): Observable<any> {
      const url = this.baseURL + 'mf7Sugarmill/lazySearch';
      return this.http.post(url,postdata);
    }
    getCaseCreationById(id): Observable<any> {
      const url = this.baseURL + apiUrls.caseCreationById + id;
      return this.http.get(url);
    }   
    getWastage(id): Observable<any> {
      const url = this.baseURL + 'mf7Sugarmill/getMstDetailsByTankId';
      return this.http.get(url);
    }
    addCaseCreation(postdata): Observable<any> {
      const url = this.scmothermodule + 'CaseCreationController';
      return this.http.post(url, postdata);
    }
    addCaseCreation1(postdata): Observable<any> {
      const url = this.scmothermodule + 'CaseCreationController/subCase/caseDetails';
      return this.http.post(url, postdata);
    }
    getMf1Report(postdata): Observable<any> {
      const url = this.baseURL + apiUrls.mf1ReportGet;
      return this.http.post(url, postdata);
    }
    caseCreationGetUpdateWorkFlow(postdata): Observable<any> {
      const url = this.baseURL + apiUrls.caseCreationGetUpdateWorkFlow;
      return this.http.put(url, postdata);
    }
    searchMf1Report(postdata): Observable<any> {
      const url = this.baseURL + apiUrls.mf1ReportSearch;
      return this.http.post(url, postdata);
    }
    getDownload(): Observable<any> {
      const url = this.baseURL + apiUrls.allotmentexportExcel;
      return this.http.get(url, { responseType: 'blob' });
    }
    getPrintData(urls: any): Observable<any> {
      const url = this.baseURL + urls;
      return this.http.get(url, { responseType: 'blob' });
    }
    gettanksDetails(postdata): Observable<any> {
      const url = this.baseURL + apiUrls.gettanksbyid;
      return this.http.post(url, postdata);
    }
    getStockInDetail(postdata): Observable<any> {
      const url = this.baseURL + apiUrls.getStockInDetail;
      return this.http.post(url, postdata);
    }
    getapplicationnumbers(data): Observable<any> {
      const url = this.baseURL + 'generateApplicationNumber?registrationType=' + data;
      return this.http.post(url, data);
    }
    getYear(): Observable<any> {
      const url = this.baseURL + 'utility/molasses/forecastDetails';
      return this.http.get(url);
    }

  }