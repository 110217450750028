export class TankConversionConfig {
    // public static titleSection = {
    //     list: {
    //         title: 'Tank Conversion',
    //         button: {
    //             text: 'Add Tank Conversion',
    //             url: '/supplychainmanagement/distillery/tankconversion/add'
    //         }
    //     },
    // };

    public static breadcrumbs = [
        {
            text: 'language.sugarmill',
            url: '/sugarmill/tank/conversion/list',
            hasHref: true
        },
        {
            text: 'language.tankconversion',
            url: '/sugarmill/tank/conversion/list',
            hasHref: true
        }
    ];

    public static columns = [
        {
            name: 'id',
            displayName: 'language.s.no',
            type: 'number'
        },
        {
            name: 'applicationNumber',
            displayName: 'brewery.tankregistrationadd.appnum',
            type: 'string'
        },
        {
            name: 'createdDate',
            displayName: 'brewery.tankMaintainence.dateofapplication',
            type: 'date',
            dateFormat: 'dd-MMM-yyyy',
        },
        {
            name: "conversionType",
            displayName: 'Converstion Type',
            type: 'string'
        },
        {
            name: "tankId",
            displayName: 'brewery.tankoverview.tankid',
            type: 'string'
        },
        {
            name: 'tankName',
            displayName: 'language.tankname',
            type: 'string'
        },
        {
            name: 'materialValue',
            displayName: 'brewery.tankoverview.designatedMaterial',
            type: 'string'
        },
        {
            name: 'tankCoverToValue',
            displayName: 'brewery.tankoverview.converto',
            type: 'string'
        },
        {
            name: 'balCapacity',
            displayName: 'Available Quantity',
            type: 'string'
        },
        {
            name: "stageDesc",
            displayName: 'Currently With',
            displayFcn: (item: any) => (item.status == 'APPROVED' || item.status == 'DRAFT' || item.status == 'SUBMITTED') ? '-' : item.stageDesc,
            type: 'objstring'
        },
        {
            name: 'statusDesc',
            displayName: 'master.common.status',
            type: 'options',
            badgeColors: {
                'Available': 'badge-primary',
                'In Progress': 'badge-secondary',
                'Approved': 'badge-success',
                'Submitted': 'badge-info',
                'Not Available': 'badge-danger',
                'Clarification': 'badge-warning',
                'Draft': 'badge-primary',
                'Dismantelled': 'badge-danger',
                'Maintenance': 'badge-danger',
                'Rejected': 'badge-danger',
                // SUMITTED: "badge-success",
                // APPROVED: "badge-primary",
                // REJECTED: "badge-danger",
                // DRAFT: "badge-warning",
                // INITIATE: "badge-success",
            }
        }
    ];

    public static columns1 = [
        {
            name: 'id',
            displayName: 'language.s.no',
            type: 'number'
        },
        {
            name: 'applicationNumber',
            displayName: 'brewery.tankregistrationadd.appnum',
            type: 'string'
        },
        {
            name: 'createdDate',
            displayName: 'brewery.tankMaintainence.dateofapplication',
            type: 'date',
            dateFormat: 'dd-MMM-yyyy',
        },
        {
            name: 'unitName',
            displayName: 'Sugar Mill Name',
            type: 'string'
        },
        {
            name: "tankId",
            displayName: 'brewery.tankoverview.tankid',
            type: 'string'
        },
        {
            name: 'tankName',
            displayName: 'language.tankname',
            type: 'string'
        },
        {
            name: 'materialValue',
            displayName: 'brewery.tankoverview.designatedMaterial',
            type: 'string'
        },
        {
            name: 'tankCoverToValue',
            displayName: 'brewery.tankoverview.converto',
            type: 'string'
        },
        {
            name: 'balCapacity',
            displayName: 'Available Quantity',
            type: 'string'
        },
        {
            name: "stageDesc",
            displayName: 'Currently With',
            displayFcn: (item: any) => (item.status == 'APPROVED' || item.status == 'DRAFT' || item.status == 'SUBMITTED') ? '-' : item.stageDesc,
            type: 'objstring'
        },
        {
            name: 'statusDesc',
            displayName: 'master.common.status',
            type: 'options',
            badgeColors: {
                'Available': 'badge-primary',
                'In Progress': 'badge-secondary',
                'Approved': 'badge-success',
                'Submitted': 'badge-info',
                'Not Available': 'badge-danger',
                'Clarification': 'badge-warning',
                'Draft': 'badge-primary',
                'Dismantelled': 'badge-danger',
                'Maintenance': 'badge-danger',
                'Rejected': 'badge-danger',
                // SUMITTED: "badge-success",
                // APPROVED: "badge-primary",
                // REJECTED: "badge-danger",
                // DRAFT: "badge-warning",
                // INITIATE: "badge-success",
            }
        }
    ];
}