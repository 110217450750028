export class ManualscanningConfig {
    public static PendingcColumns = [
        {
            name: 'id',
            displayName:  'brewery.common.s.no',
            displayFcn: (item: any) => item.sr_no,
            type: 'number'
        },
        {
            name: 'date_of_packaging',
            displayName: 'Bottling Date',
            displayFcn: (item: any) => item.date_of_packaging,
            type: 'date'
        },
        {
            name: 'bottling_plan_id',
            displayName: 'brewery.manualscanning.bottlingplanid',
            displayFcn: (item: any) => item.bottling_plan_id,
            type: 'objstring'
        },
        {
            name: 'brand_value',
            displayName: 'brewery.manualscanning.brandname',
            displayFcn: (item: any) => item.brand_value,
            type: 'objstring'
        },
        {
            name: 'no_of_cases',
            displayName: 'brewery.manualscanning.noofcasesormonocartons',
            displayFcn: (item: any) => item.no_of_cases,
            type: 'objstring',
            align:'center'
        },
       
        {
            name: 'no_of_bottles',
            displayName: 'No Of Bottles',
            displayFcn: (item: any) => item.no_of_bottles,
            type: 'objstring',
            align:'center'
        }
      
        // {
        //     name: 'status1',
        //     displayName: 'brewery.common.status',
        //     displayFcn: (item: any) => item.status1,
        //     type: 'options',
        //     badgeColors: {
        //         PENDING: 'badge-success'
        //     }

        // },
    ];
    public static InProgressColumns = [
        {
            name: 'id',
            displayName:  'brewery.common.s.no',
            displayFcn: (item: any) => item.sr_no,
            type: 'number'
        },
        {
            name: 'date_of_packaging',
            displayName: 'Bottling Date',
            displayFcn: (item: any) => item.date_of_packaging,
            type: 'date'
        },
        {
            name: 'bottling_plan_id',
            displayName: 'brewery.manualscanning.bottlingplanid',
            displayFcn: (item: any) => item.bottling_plan_id,
            type: 'objstring'
        },
        {
            name: 'brand_value',
            displayName: 'brewery.manualscanning.brandname',
            displayFcn: (item: any) => item.brand_value,
            type: 'objstring'
        },
        {
            name: 'no_of_cases',
            displayName: 'brewery.manualscanning.noofcasesormonocartons',
            displayFcn: (item: any) => item.no_of_cases,
            type: 'objstring',
            align:'center'
        },
       
        {
            name: 'no_of_bottles',
            displayName: 'No Of Bottles',
            displayFcn: (item: any) => item.no_of_bottles,
            type: 'objstring',
            align:'center'
        },
      
        // {
        //     name: 'status1',
        //     displayName: 'brewery.common.status',
        //     displayFcn: (item: any) => item.status1,
        //     type: 'options',
        //     badgeColors: {
        //         'IN-PROGRESS': 'badge-success'
        //     }

        // },
    ];
    public static CompletedColumns = [
        {
            name: 'id',
            displayName:  'brewery.common.s.no',
            displayFcn: (item: any) => item.sr_no,
            type: 'number'
        },
        {
            name: 'date_of_packaging',
            displayName: 'Bottling Date',
            displayFcn: (item: any) => item.date_of_packaging,
            type: 'date'
        },
        {
            name: 'bottling_plan_id',
            displayName: 'brewery.manualscanning.bottlingplanid',
            displayFcn: (item: any) => item.bottling_plan_id,
            type: 'objstring'
        },
        {
            name: 'brand_value',
            displayName: 'brewery.manualscanning.brandname',
            displayFcn: (item: any) => item.brand_value,
            type: 'objstring'
        },
        {
            name: 'no_of_cases',
            displayName: 'brewery.manualscanning.noofcasesormonocartons',
            displayFcn: (item: any) => item.no_of_cases,
            type: 'objstring',
            align:'center'
        },
       
        {
            name: 'no_of_bottles',
            displayName:'No Of Bottles',
            displayFcn: (item: any) => item.no_of_bottles,
            type: 'objstring',
            align:'center'
        }
        
        // {
        //     name: 'status1',
        //     displayName: 'brewery.common.status',
        //     displayFcn: (item: any) => item.status1,
        //     type: 'options',
        //     badgeColors: {
        //         COMPLETED: 'badge-success'
        //     }
        // },
    ];
    public static columnsReport = [
        {
            name: 'id',
            displayName: 'S No',
            type: 'number'
        },
        {
            name: 'dispatchDatee',
            displayName: 'Disaptch Date',
            type: 'date'
        },
        {
            name: 'transportPassNoe',
            displayName: 'Transport Pass No',
            type: 'string'
        },
        {
            name: 'unitNamee',
            displayName: 'Unit Name',
            type: 'string'
        },
        {
            name: 'totScannedCasese',
            displayName: 'Cases Dispatched',
            type: 'string'
        },
        {
            name: 'totScannedBottlese',
            displayName: 'Bottles Dispatched',
            type: 'string'
        },

    ];
}
