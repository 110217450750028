import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '@appEnv/environment';
import { apiUrls } from '@appEnv/apiurls';
@Injectable({
  providedIn: 'root'
})
export class TransToGinService {

  constructor(
    private http: HttpClient
  ) { }
  baseURL = environment.parentUnit;

  lazySearch(postdata): Observable<any> {
    const url = this.baseURL + 'ginTransfer/lazySearch';
    return this.http.post(url, postdata);
  }
  addTrans(postdata): Observable<any> {
    const url = this.baseURL + 'ginTransfer';
    return this.http.post(url, postdata);
  }
  getById(id): Observable<any> {
    const url = this.baseURL + 'ginTransfer/'+ id;
    return this.http.get(url, id);
  }
  downloadinspection(payload){
    const url = this.baseURL + 'reportReceipt/downloadReceipt'
      // const url = 'http://localhost:3501/scmProduction/reportReceipt/downloadReceipt';
    return this.http.get(url,{ responseType: 'blob', params:payload });
  }

  getPdCardCount(payload): Observable<any> {
    const url = this.baseURL + 'pd/getStatusCount'
    return this.http.post(url,payload);
  }

}