
const pdParfix = "PD";
const indParfix = "ID";
const mixedParfix = "MIXED";


export class oTLocalUrl {
  public static pdotproductionplan = {
    registrationType: 'PD_',
    entityType: 'PD',
    moduleName: 'pd',
    url: 'pd',
    workflowModule: pdParfix,
    workflowSubModule: pdParfix+'_OT_PRODUCTION_PLAN',
  };
 
  public static pdyeastprocess = {
    registrationType: 'PD_',
    entityType: 'PD',
    moduleName: 'pd',
    url: 'pd',
    workflowModule: pdParfix,
    workflowSubModule: pdParfix+'_OT_PRODUCTION_PLAN',
  };

  public static pdprefermenter = {
    registrationType: 'PD_',
    entityType: 'PD',
    moduleName: 'pd',
    url: 'pd',
    workflowModule: pdParfix,
    workflowSubModule: pdParfix+'_OT_PRODUCTION_PLAN',
  };

  public static pdfermentation = {
    registrationType: 'PD_',
    entityType: 'PD',
    moduleName: 'pd',
    url: 'pd',
    workflowModule: pdParfix,
    workflowSubModule: pdParfix+'_OT_PRODUCTION_PLAN',
  };
  
  public static pdsendtostill = {
    registrationType: 'PD_',
    entityType: 'PD',
    moduleName: 'pd',
    url: 'pd',
    workflowModule: pdParfix,
    workflowSubModule: pdParfix+'_SENT_STILL',
  };

  public static pddeclarationofcot = {
    registrationType: 'PD_',
    entityType: 'PD',
    moduleName: 'pd',
    url: 'pd',
    workflowModule: pdParfix,
    workflowSubModule: pdParfix+'_COT',
  };

  public static pdotdeclaration = {
    registrationType: 'PD_',
    entityType: 'PD',
    moduleName: 'pd',
    url: 'pd',
    workflowModule: pdParfix,
    workflowSubModule: pdParfix+'_OT_DECLARATION_WITHOUT_PLAN',
  };


  
  // ind


  public static indotproductionplan= {
    registrationType: 'ID_',
    entityType: 'ID',
    moduleName: 'id',
    url: 'ind',
    workflowModule: indParfix,
    workflowSubModule: indParfix + '_OT_PRODUCTION_PLAN',
  };
  public static indyeastprocess= {
    registrationType: 'ID_',
    entityType: 'ID',
    moduleName: 'id',
    url: 'ind',
    workflowModule: indParfix,
    workflowSubModule: indParfix + '_OT_PRODUCTION_PLAN',
  };
  public static indprefermenter= {
    registrationType: 'ID_',
    entityType: 'ID',
    moduleName: 'id',
    url: 'ind',
    workflowModule: indParfix,
    workflowSubModule: indParfix + '_OT_PRODUCTION_PLAN',
  };
  public static indfermentation= {
    registrationType: 'ID_',
    entityType: 'ID',
    moduleName: 'id',
    url: 'ind',
    workflowModule: indParfix,
    workflowSubModule: indParfix + '_OT_PRODUCTION_PLAN',
  };
  
  public static indsendtostill= {
    registrationType: 'ID_',
    entityType: 'ID',
    moduleName: 'id',
    url: 'ind',
    workflowModule: indParfix,
    workflowSubModule: indParfix + '_SENT_STILL',
  };
  public static inddeclarationofcot = {
    registrationType: 'ID_',
    entityType: 'ID',
    moduleName: 'id',
    url: 'ind',
    workflowModule: indParfix,
    workflowSubModule: indParfix+'_COT',
  };
  public static indotdeclaration = {
    registrationType: 'ID_',
    entityType: 'ID',
    moduleName: 'id',
    url: 'ind',
    workflowModule: indParfix,
    workflowSubModule: indParfix+'_OT_DECLARATION_WITHOUT_PLAN',
  };
  //mixed
  
  public static mixedotproductionplan = {
    registrationType: 'MIXED_',
    entityType: 'MIXED',
    moduleName: 'mixed',
    url: 'mixed',
    workflowModule: mixedParfix,
    workflowSubModule: mixedParfix + '_OT_PRODUCTION_PLAN',
  };
  public static mixedyeastprocess = {
    registrationType: 'MIXED_',
    entityType: 'MIXED',
    moduleName: 'mixed',
    url: 'mixed',
    workflowModule: mixedParfix,
    workflowSubModule: mixedParfix + '_OT_PRODUCTION_PLAN',
  };
  public static mixedprefermenter = {
    registrationType: 'MIXED_',
    entityType: 'MIXED',
    moduleName: 'mixed',
    url: 'mixed',
    workflowModule: mixedParfix,
    workflowSubModule: mixedParfix + '_OT_PRODUCTION_PLAN',
  };
  public static mixedfermentation = {
    registrationType: 'MIXED_',
    entityType: 'MIXED',
    moduleName: 'mixed',
    url: 'mixed',
    workflowModule: mixedParfix,
    workflowSubModule: mixedParfix + '_OT_PRODUCTION_PLAN',
  };
  
  public static mixedsendtostill = {
    registrationType: 'MIXED_',
    entityType: 'MIXED',
    moduleName: 'mixed',
    url: 'mixed',
    workflowModule: mixedParfix,
    workflowSubModule: mixedParfix + '_SENT_STILL',
  };
  public static mixeddeclarationofcot = {
    registrationType: 'MIXED_',
    entityType: 'MIXED',
    moduleName: 'mixed',
    url: 'mixed',
    workflowModule: mixedParfix,
    workflowSubModule: mixedParfix +'_COT',
  };
  public static mixedotdeclaration = {
    registrationType: 'MIXED_',
    entityType: 'MIXED',
    moduleName: 'mixed',
    url: 'mixed',
    workflowModule: mixedParfix,
    workflowSubModule: mixedParfix +'_OT_DECLARATION_WITHOUT_PLAN',
  };

  

  public static pddenatureprocess = {
    registrationType: 'PDDNP_',
    entityType: 'PD',
    moduleName: 'pd',
    url: 'pd',
    workflowModule: pdParfix,
    workflowSubModule: pdParfix + '_DENATURATION_PROCESS',
  };
  public static inddenatureprocess = {
    registrationType: 'IDDNP_',
    entityType: 'ID',
    moduleName: 'id',
    url: 'ind',
    workflowModule: indParfix,
    workflowSubModule: indParfix + '_DENATURATION_PROCESS',
  };

  public static mixeddenatureprocess = {
    registrationType: 'MDDNP_',
    entityType: 'MIXED',
    moduleName: 'mixed',
    url: 'mixed',
    workflowModule: mixedParfix,
    workflowSubModule: mixedParfix + '_DENATURATION_PROCESS',
  };

  public static pdfermentationwithoutplan = {
    registrationType: 'PD_FP_',
    entityType: 'PD',
    moduleName: 'pd',
    url: 'pd',
    workflowModule: pdParfix,
    workflowSubModule: pdParfix + '_DENATURATION_PROCESS',
  };
  public static indfermentationwithoutplan = {
    registrationType: 'ID_FP_',
    entityType: 'ID',
    moduleName: 'id',
    url: 'ind',
    workflowModule: indParfix,
    workflowSubModule: indParfix + '_DENATURATION_PROCESS',
  };

  public static mixedfermentationwithoutplan = {
    registrationType: 'MD_FP_',
    entityType: 'MIXED',
    moduleName: 'mixed',
    url: 'mixed',
    workflowModule: mixedParfix,
    workflowSubModule: mixedParfix + '_DENATURATION_PROCESS',
  };

  public static pddeclarationofcotwithoutplan = {
    registrationType: 'PD_',
    entityType: 'PD',
    moduleName: 'pd',
    url: 'pd',
    workflowModule: pdParfix,
    workflowSubModule: pdParfix+'_COT_DECLARATION',
  };

  public static inddeclarationofcotwithoutplan = {
    registrationType: 'ID_',
    entityType: 'ID',
    moduleName: 'id',
    url: 'ind',
    workflowModule: indParfix,
    workflowSubModule: indParfix+'_COT_DECLARATION',
  };

 public static mixeddeclarationofcotwithoutplan = {
    registrationType: 'MIXED_',
    entityType: 'MIXED',
    moduleName: 'mixed',
    url: 'mixed',
    workflowModule: mixedParfix,
    workflowSubModule: mixedParfix +'_COT_DECLARATION',
  };



}
