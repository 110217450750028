import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '@appEnv/environment';
import { apiUrls } from '@appEnv/apiurls';
@Injectable({
  providedIn: 'root'
})
export class MolassesExportService {

  constructor(
    private http: HttpClient
  ) { }

  baseURL = environment.parentUnit;
  devisionUrl = environment.devisionUrl;
  licenseURL = environment.licneseBaseUrl;

  addExportOfMolasses(payload):Observable<any>{
    const url = this.baseURL + 'exportOfMolasses'
    return this.http.post(url, payload);
  }
  validityExtension(payload):Observable<any>{
    const url = this.baseURL + 'exportOfMolasses/sub/updateValidityExtension';
    return this.http.post(url, payload);
  }
  getExportOfMolassesById(id):Observable<any>{
    const url = this.baseURL + 'exportOfMolasses/' + id;
    return this.http.get(url);
  }

  exportOfMolassesLazySearch(payload):Observable<any>{
    const url = this.baseURL + 'exportOfMolasses/lazySearch';
    return this.http.post(url, payload);
  }

  getModeOfTransfer(): Observable<any> {
    const url = environment.devisionUrl + 'api/masterValues/findDropDownMaster?dropDownKey=MODE_OF_TRANSFER'
    return this.http.get(url);
  }
  getSugarMillTypes(): Observable<any> {
    const url = environment.devisionUrl + 'api/masterValues/findDropDownMaster?dropDownKey=SUGARMILL_TYPE'
    return this.http.get(url);
  }
  getSugarMillTypesMolassesExportPermit(): Observable<any> {
    const url = environment.devisionUrl + 'api/masterValues/findDropDownMaster?dropDownKey=SUGARMILL_EXPORT'
    return this.http.get(url);
  }
  getReservationType(): Observable<any> {
    const url = environment.devisionUrl + 'api/masterValues/findDropDownMaster?dropDownKey=RESERVATION_TYPE'
    return this.http.get(url);
  }
  getMolassesTypes(): Observable<any> {
    const url = environment.devisionUrl + 'api/masterValues/findDropDownMaster?dropDownKey=DESIGNATED_MATERIAL&additionalFilter2=MOLASSES'
    return this.http.get(url);
  }
  getMolassesGrades(): Observable<any> {
    const url = environment.devisionUrl + 'api/masterValues/findDropDownMaster?dropDownKey=MOLASSES_GRADE'
    return this.http.get(url);
  }
  getapplicationnumbers(data): any {
    const url = this.baseURL + 'generateApplicationNumber?registrationType=' + data.registrationType + '&licenseNumber=' + data.licenseNumber;
    return this.http.post(url, {}).toPromise();
  }
  getSugarMillDetails1(entityType): any {
    const url = this.licenseURL + 'license/category/' + entityType + '?optionalCategory=SUGAR_MILL';
    return this.http.get(url).toPromise();
  }
  getSugarMillListDetails(entityType, groupName): any {
    const url = this.licenseURL + 'license/category/sugarMill/SUGAR_MILL?groupName='+ groupName +'&page=0&size=0';
    return this.http.get(url).toPromise();
  }
  getPdCardCount(payload): Observable<any> {
    const url = this.baseURL + 'pd/getStatusCount'
    return this.http.post(url,payload);
  }
  AcceptanceOfMolassesExportpermitLazySearch(payload):Observable<any>{
    const url = this.baseURL + 'exportOfMolassesAcceptance/lazySearch';
    return this.http.post(url, payload);
  }
  AcceptanceOfMolassesExportpermitGetById(id):Observable<any>{
    const url = this.baseURL + 'exportOfMolassesAcceptance/getIndentById?id=' +id;
    return this.http.get(url);
  }
  AcceptanceOfMolassesExportpermitUpdate(payload):Observable<any>{
    const url = this.baseURL + 'exportOfMolassesAcceptance/indentRequestUpdate';
    return this.http.post(url, payload);
  }
  downloadinspection(payload){
    const url = this.baseURL + 'reportReceipt/downloadReceipt'
      // const url = 'http://localhost:3501/scmProduction/reportReceipt/downloadReceipt';
    return this.http.get(url,{ responseType: 'blob', params:payload });
  }
  getMolassesYear(): Observable<any> {
    const url = this.baseURL + 'mf9fortnight/getProductionForecast'
    return this.http.get(url);
  }
}
