import * as moment from "moment";

export class cbwTransitgatePassconfig {
    public static columns = [
      {
        name: 'id',
        displayName: 'wholesale.indentrequest.slno',
        type: 'number'
      },
      {
        name: 'tp_reference_number',
        displayName: 'wholesale.transportpassgen.tranportpassnumber',
        type: 'string'
      },
      {
        name: 'draft_tp_date',
        displayName: 'Issued Date',
        type: 'date',
        dateFormat: 'dd-MMM-yyyy',
      },
      {
        name: 'date',
        displayName: 'Created Date',
        displayFcn: (item: any) => moment(item.tp_created_date).format("DD-MMM-YYYY"),
        type: 'objstring'
      },
  
      {
        name: 'from_entity',
        displayName: 'wholesale.transportpassgen.fromentity',
        type: 'string'
      },
      {
        name: 'to_entity',
        displayName: 'wholesale.transportpassgen.toentity',
        type: 'string'
      },
      {
        name: 'no_of_indent',
        displayName: 'Total No Of Indent/Import',
        type: 'string',
        dateFormat: 'dd-MMM-yyyy',
      },
      // {
      //   name: 'no_of_bottles',
      //   displayName: 'wholesale.transportpassgen.totalqty',
      //   type: 'string'
      // },
      {
        name: 'no_of_cases_requested',
        displayName: 'Total No. of Cases/Monocartons Requested',
        type: 'string',
        align:'center'

      },
      {
        name: 'no_of_bottles_requested',
        displayName: 'wholesale.transportpassgen.noofbottlesrequested',
        type: 'string',
        align:'center'
      },
      {
        name: 'no_of_cases_dispatached',
        displayName: 'Total No. of Cases/Monocartons Dispatched',
        type: 'string',
        align:'center'
      },
      {
        name: 'no_of_bottles_dispatached',
        displayName: 'Total No. of Bottles Dispatched',
        type: 'string',
        align:'center'
      },
      {
        name: 'status',
        displayName: 'wholesale.indentrequest.status',
        type: 'options',
        badgeColors: {
          SUBMITTED: 'badge-info',
          DRAFT: 'badge-warning',
          ACCEPTED: 'badge-success',
          APPROVED: 'badge-warning',
          CANCELLED: 'badge-danger',
          REJECTED: 'badge-danger',
          'WAITING FOR REQUEST CLARIFICATION': 'badge-primary', 
          
          DRAFT_TP_SUBMITTED:'badge-info',
          FINAL_TP_SUBMITTED:'badge-info',
          DRAFT_TP_APPROVED:'badge-success',
          FINAL_TP_APPROVED:'badge-success',
          DRAFT_TP_CLARIFICATION_REQUESTED:'badge-warning',
          FINAL_TP_CLARIFICATION_REQUESTED:'badge-warning',
          DRAFT_TP_REJECTED:'badge-danger',
          FINAL_TP_REJECTED:'badge-danger',
        }
      },     
      {
        name: 'ack_status',
        displayName: 'wholesale.transportpassgen.dispatch/akstatus',
        type: 'string'
      },
    ];
  }