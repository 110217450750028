export class ShopConfig {
  
    public static columns = [
      {
        name: 'id',
        displayName: '#',
        type: 'number'
      },
      {
        name: 'shopId',
        displayName: 'Shop Code',
        type: 'string'
      },
      {
        name: 'nameOfShop',
        displayName: 'Shop Name',
        type: 'string'
      },
      // {
      //   name: 'nameOfLicensee',
      //   displayName: 'License Name',
      //   type: 'string'
      // },
      {
        name: 'districtDesc',
        displayName: 'District',
        type: 'string'
      },
      // {
      //   name: 'status',
      //   displayName: 'master.common.status',
      //   type: 'boolean',
      //   optionVal: ['Active', 'In-Active']
      // }
    ];

    public static beerPermitColumns = [
      {
        name: 'id',
        displayName: '#',
        type: 'number'
      },
      {
        name: 'applicationNumber',
        displayName: 'Application Number',
        type: 'string'
      },
      {
        name: 'shopId',
        displayName: 'Shop Id',
        type: 'string'
      },
      {
        name: 'shopName',
        displayName: 'shop Name',
        type: 'string'
      },
      {
        name: 'districtDesc',
        displayName: 'District',
        type: 'string'
      },
      {
        name: 'stage',
        displayName: 'Currently With',
        type: 'string'
      },
      {
        name: 'statusDesc',
        displayName: 'Application Status',
        type: 'string'
      }
    ] 
  
  }
  