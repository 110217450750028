import * as moment from "moment";
export class Bottlingplancancellation {
    id:number;
    bottling_plan_id: String;
    brand_name: String;
    planned_bottles: Number;
    bottled_bl: Number;
    status_desc: String;
    status: String;
    created_date: String;
    cancellation_status: String;
    amount_paid: String;
    payment_status: String;
    fine_amount: Number;


    public static listColomn = [
        {
            name: 'id',
            displayName:'language.s.no' ,
            type: 'number'
        },
        {
            name: 'date',
            displayName: 'language.bottlingplandate',
            displayFcn: (item: any) => moment(item.created_date).format("DD-MM-YYYY"),
            type: 'objstring'
        },
        {
            name: 'brand_name',
            displayName: 'language.brandname',
            type: 'string'
        },
        {
            name: 'cancellation_status',
            displayName: 'language.cancellationstatus',
            type: 'string'
        },
        {
            name: 'bottling_plan_id',
            displayName: 'language.bottlingplanid',
            type: 'string'
        },
        {
            name: 'no_of_bottles',
            displayName: 'language.plannedbottls',
            type: 'string'
        },
        {
            name: 'status_desc',
            displayName: 'language.status',
            type: 'string'
        }
        
    ]
}