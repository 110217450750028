
 
export class statusList {

  public static statusList = [
    {
      code: 'INITIATE',
      value: "Submit"
    },
    {
      code: 'IN_PROGRESS',
      value: "In Progress"
    },
    {
      code: 'DRAFT',
      value: "Draft"
    },
    {
      code: 'SUBMITTED',
      value: "Submitted"
    },
    {
      code: 'APPROVED',
      value: "Approved"
    },
    {
      code: 'ACCEPTED',
      value: "Accepted"
    },
    {
      code: 'REJECTED',
      value: "Rejected"
    },
    {
      code: 'PENDING',
      value: "Pending"
    },
    {
      code: 'INACTIVE',
      value: "InActive"
    },
    {
      code: 'SENDBACK',
      value: "Clarification"
    },
    {
      code: 'EXPIRED',
      value: "Expired"
    },
    {
      code: 'HISTORY',
      value: "History"
    },
    {
      code: 'CANCELLED',
      value: "Cancelled"
    },
    {
      code: 'MAINTENANCE',
      value: "Maintenance"
    },
    {
      code: 'DISMANTLED',
      value: "Dismantled"
    },
    {
      code: 'CANCELL_IN_PROGRESS',
      value: "Cancellation InProgress"
    },
    {
      code: 'MODIFY_IN_PROGRESS',
      value: "Modify InProgress"
    },
    {
      code: 'COMPLETED',
      value: "Completed"
    },
    {
      code: 'ACKNOWLEDGED',
      value: "acknowledged"
    },


  ]
}
