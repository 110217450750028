export class ProductionDeclarationConfig {
    public static columns = [
        {
            name: 'id',
            displayName: 'retailpos.dispatch.sno',
            type: 'number'
        },
        {
            name: 'date_of_declaration',
            displayName: 'language.dateofdeclaration',
            displayFcn: (item: any) => item.date_of_declaration,
            type: 'date',
            dateFormat: 'dd-MMM-yyyy',
        },
        {
            name: 'bottling_plan_id',
            displayName: 'language.bottlingplanid',
            displayFcn: (item: any) => item.bottling_plan_id,
            type: 'objstring'
        },
        {
            name: 'brand_name',
            displayName: 'language.brandname',
            displayFcn: (item: any) => item.brand_name,
            type: 'objstring'
        },
        {
            name: 'planned_bottles',
            displayName: 'language.plannedbottls',
            displayFcn: (item: any) => item.planned_bottles,
            type: 'objstring'
        },
        {
            name: 'bottled_bl',
            displayName: 'language.bottledbl',
            displayFcn: (item: any) => item.bottled_bl,
            type: 'objstring'
        },
        // {
        //     name: 'wastage_of_bottling_bl',
        //     displayName: 'language.wastageofbotting',
        //     displayFcn: (item: any) => item.wastage_of_bottling_bl,
        //     type: 'objstring'
        // },
        {
            name: "status",
            displayName: 'language.status',
            type: 'options',
            badgeColors: {
              'Draft': 'badge-primary',
              'In Progress': 'badge-secondary',
              APPROVED: 'badge-success',
              SUBMITTED: 'badge-info',
              REJECTED: 'badge-danger',
              CANCELLATION_APPROVED:'badge-success',
              'CLARIFICATION REQUESTED': 'badge-warning',
              CANCELLATION_SUBMITTED:'badge-info',
            }
          },
    ]
}