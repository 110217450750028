export class Indentacceptcattlefieldconfig {

    // public static titleSection = {
    //   list: {
    //       title: 'master.district.list',
    //       button: {
    //           text: 'master.district.adddistrict',
    //           url: '/location/district/add'
    //       }
    //   },
    //   add: {
    //       title: 'master.district.list'
    //   },
    //   edit: {
    //       title: 'master.district.list'
    //   },
    //   view: {
    //       title: 'master.district.list'
    //   }
    // };

    public static breadcrumbs = [
        {
            text: 'language.sugarmill',
            url: '/sugarmill/indent-acceptance-cattlefield-insideup/list',
            hasHref: true
        },
        {
            text: 'language.indentcattlefield',
            url: '/sugarmill/indent-acceptance-cattlefield-insideup/list',
            hasHref: true
        }
    ];

    public static columns = [
        {
            name: 'id',
            displayName: 'language.s.no',
            type: 'number'
        },
        {
            name: 'indentDate',
            displayName: 'sugarmill.Indentreq.indentdate',
            type: 'date',
            dateFormat: 'dd/MM/yyyy',
        },
        {
            name: 'applicationNumber',
            displayName: 'Indent No',
            type: 'string'
        },
        {
            name: "stageDesc",
            displayName: 'language.currentlywith',
            displayFcn: (item: any) => (item.status == 'APPROVED' || item.status == 'DRAFT' || item.status == 'SUBMITTED') ? '-' : item.stageDesc,
            type: 'objstring'
        },
        {
            name: 'sugarMillStatus',
            displayName: 'sugarmill.common.status',
            type: 'options',
            badgeColors: {
                'ACCEPTED': 'badge-success',
                'PENDING': 'badge-warning'
            }
        }
    ];
}