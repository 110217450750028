export const AuditManConst = {

    STATUS_DRAFT: 'DRAFT',
    STATUS_SAVE: 'SAVE',
    STATUS_SUBMITTED: 'SUBMITTED',
    STATUS_APPROVED: 'APPROVED',
    STATUS_REJECTED: 'REJECTED',
    STATUS_SENDBACK: 'SENDBACK',
    STATUS_IN_PROGRESS: 'IN_PROGRESS',

    MODULE: 'AUDIT_MGMT',
    ENTITY_TYPE: 'AUDIT_MGMT',
    ENTITY_NAME: 'Audit Management'
}