import * as moment from "moment";

export class PalletMaster {
    id: number;
    entity_code: String;
    license_type: Number;
    license_number: String;
    pallet_id: String;
    pallet_qr_code: String;
    status: String;
    



    public static indCanApp = [
        {
            name: 'id',
            displayName: 'S.No',
            type: 'number'
        },
        {
            name: 'indent_number',
            displayName: 'Indent Number',
            type: 'string'
        },
        {
            name: 'indent_date',
            displayName: 'Indent Date',
            type: 'string'
        },
        {
            name: 'total_cases',
            displayName: 'Total Cases',
            type: 'number'
        },
        {
            name: 'total_bottles',
            displayName: 'Total Bottle',
            type: 'number'
        },
        {
            name: 'entity_type_code',
            displayName: 'Entity Type Code',
            type: 'string'
        },
        {
            name: 'to_entity_code',
            displayName: 'To Entity Code',
            type: 'string'
        },
        {
            name: 'status',
            displayName: 'Status',
            type: 'string'
        }
    ]


    /**
     * Mst Approval List Variables
     */
    reference_number: String;
    verification_for_month: String;
    verification_for_year: String
    inspection_type: String;
    performed_by: String;
    inspection_date: String;
    // joinDate = this.verification_for_month + "" + this.verification_for_year;

    /**
     * Mst Approval Page Column Value
     */

    public static mstListColumns = [
        {
            name: 'id',
            displayName: 'S.No',
            type: 'number'
        },
        {
            name: 'reference_number',
            displayName: 'Stock Code',
            type: 'string'
        },
        {
            name: 'inspection_type',
            displayName: 'Inspection Type',
            type: 'string'
        },
        {
            name: 'license_type',
            displayName: 'Licence Type',
            type: 'string'
        },
        {
            name: 'status',
            displayName: 'Status',
            type: 'string'
        },
        {
            name: 'verification_for_month',
            displayName: 'Stock Month_Year',
            type: 'string'
        },
        {
            name: 'inspection_date',
            displayName: 'Inspection Date',
            type: 'string'
        },
        {
            name: 'license_number',
            displayName: 'Licence Number',
            type: 'string'
        },
        {
            name: 'performed_by',
            displayName: 'Performed By',
            type: 'string'
        }

    ]

    /**
     * Accidental Request Approval Page Column Value
     */
    public static accidentalReequest = [
        // {
        //     name: 'id',
        //     displayName: 'S.No',
        //     type: 'number'
        // },
        {
            name: "id",
            displayName: 'brewery.tankRepair.requestId',
            type: "string"
        },
        {
            name: "accident_type_value",
            displayName: 'language.accidentaltype',
            type: "string"
        },
        {
            name: "request_date",
            displayName: 'language.requestDate',
            displayFcn: (item: any) => moment(item.request_date).format("DD-MM-YYYY"),
            type: "objstring"
        },
        {
            name: "status",
            displayName: 'language.status',
            type: "options",
            badgeColors: {
                'ACKNOWLEDGED': 'badge-success',
            }
        },
        {
            name: "from_district_short_name",
            displayName: 'language.accidentLocationDistrict',
            type: "string"
        },



    ]

    /**
     *  Breakage Entry Monthly Approval Page Column Value
     */
    public static breakageEnMonApp = [
        {
            name: 'id',
            displayName: 'S.No',
            type: 'number'
        },
        {
            name: 'reference_number',
            displayName: 'Referance Number',
            type: 'string'
        },
        {
            name: 'from_date',
            displayName: 'From Date',
            type: 'string'
        },
        {
            name: 'total_damaged_cases',
            displayName: 'Total No. of Damaged Cases/Monocartons',
            type: 'string'
        },
        {
            name: 'total_missing_cases',
            displayName: 'Total No. of Missing Cases/Monocartons',
            type: 'string'
        },
        {
            name: 'status',
            displayName: 'Status',
            type: 'string'
        },
        {
            name: 'to_date',
            displayName: 'To Date',
            type: 'string'
        },
        {
            name: 'total_damaged_bottles',
            displayName: 'Total No. of Damaged Bottles/Monocartons',
            type: 'string'
        },
        {
            name: 'total_missing_bottles',
            displayName: 'Total No. of Missing Bottles/Monocartons',
            type: 'string'
        }
    ]

    public static breakageEntryMonthlyList = [
        {
            name: 'id',
            displayName: 'S.No',
            type: 'number'
        },
        {
            name: 'reference_number',
            displayName: 'Referance Number',
            type: 'string'
        },
        {
            name: 'from_date',
            displayName: 'From Date',
            type: 'string'
        },
        {
            name: 'total_damaged_cases',
            displayName: 'Total No. of Damaged Cases/Monocartons',
            type: 'string'
        },
        {
            name: 'total_missing_cases',
            displayName: 'Total No. of Missing Cases/Monocartons',
            type: 'string'
        },
        {
            name: 'status',
            displayName: 'Status',
            type: 'string'
        },
        {
            name: 'to_date',
            displayName: 'To Date',
            type: 'string'
        },
        {
            name: 'total_damaged_bottles',
            displayName: 'Total No. of Damaged Bottles/Monocartons',
            type: 'string'
        },
        {
            name: 'total_missing_bottles',
            displayName: 'Total No. of Missing Bottles/Monocartons',
            type: 'string'
        }
    ]

}




