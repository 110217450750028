import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '@appEnv/environment';
import { apiUrls } from '@appEnv/apiurls';
@Injectable({
  providedIn: 'root'
})

export class OrderPickupOmdService {

    constructor(private http: HttpClient) { }
    baseURL = environment.parentUnit;
    devisionUrl = environment.devisionUrl;
    licenseURL = environment.licneseBaseUrl;
    masterURL = environment.devisionUrl;
    
    getStatusMasters() {
      const url = this.masterURL + "api/masterValues/findDropDownMaster?dropDownKey=WHOLESALE_STATUS_INDENTREQ"
      return this.http.get(url)
     }
  

  
  
     getdetails(): Observable<any> {//work
      const url = this.licenseURL + 'license/category/DISTILLERY';
      return this.http.get(url);
    }

    getapplicationnumbers(data): Observable<any> {
      const url = this.baseURL + 'generateApplicationNumber?registrationType= ' + data;
      return this.http.post(url, data);
    }

    addOrderPickupOmdDetails(data): Observable<any> {
      const url = this.baseURL + 'orderPickupOMD';
      return this.http.post(url, data);
  }

  OrderPickupOmdDetailsById(id): Observable<any> {
    const url = this.baseURL + 'orderPickupOMD/' + id;
    return this.http.get(url);
  }

  // getPD26Details(payload): Observable<any>{
  //   const url = this.baseURL + 'pd26/lazySearch';
  //   return this.http.get(url, payload);
  // }

  searchpd26(payload): Observable<any> {
    const url = this.baseURL + 'pd26/lazySearch';
    return this.http.post(url, payload);
  }

  getByIdpd26(id): Observable<any> {
    const url = this.baseURL + 'pd26/' + id;
    return this.http.get(url);
  }

  getLicenseDetails(): Observable<any> {//work
    const url = this.licenseURL + 'license/category/DISTILLERY';
    return this.http.get(url);
  }

  search(payload): Observable<any>
  {
      const url = this.baseURL + "orderPickupOMD/lazySearch"
      return this.http.post(url, payload);
  }
  
  
  }