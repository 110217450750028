import * as moment from "moment";

export class Importpermitrequestconfig {

  public static breadcrumbs = [
    {
      text: 'Bond Warehouse',
      url: '/bondWarehouse/importpermitrequest/list',
      hasHref: true
    },
    {
      text: 'Import Permit Request',
      url: '/bondWarehouse/importpermitrequest/list',
      hasHref: true
    },
    {
      text: 'List',
      url: '/bondWarehouse/importpermitrequest/list',
      hasHref: true
    },
    // {
    //   text: 'master.common.list',
    //   hasHref: false
    // }
  ]
  public static columns = [
    {
      name: 'id',
      displayName: 'S.No.',
      type: 'number'
    },

    {
      name: 'indent_number',
      displayName: 'Import Permit Number',
      type: 'string'
    },
    {
      name: 'created_date',
      displayName: 'Permit Date',
      displayFcn: (item: any) => moment(item.created_date).format("DD-MM-YYYY"),
      type: 'date'
    },
    {
      name: 'total_cases',
      displayName: 'Total No. of Cases/Monocartons',
      type: 'string'
    },
    {
      name: 'total_bottles',
      displayName: 'Total No. of Bottles',
      type: 'string'
    },
    {
      name: 'from_entity_type_code',
      displayName: 'Unit Type',
      type: 'string',
      align: 'center'
    },
    {
      name: 'from_unit_name',
      displayName: 'Unit Name',
      type: 'string',
      align: 'center'
    },
    // {
    //   name: 'unitType',
    //   displayName: 'Indent No',
    //   displayFcn: (item: any) => item.unitType,
    //   type: 'objstring'
    // },
    // {
    //   name: 'totNoOfCases',
    //   displayName: 'wholesale.indentrequest.totalcases',
    //   displayFcn: (item: any) => item.totNoOfCases,
    //   type: 'objstring'
    // },
    // {
    //   name: 'totNoOfBottles',
    //   displayName: 'wholesale.indentrequest.totalbottles',
    //   displayFcn: (item: any) => item.totNoOfBottles,
    //   type: 'objstring'
    // },
    {
      name: 'status',
      displayName: 'Status',
      type: 'options',
      badgeColors: {
        SUBMITTED: 'badge-info',
        DRAFT: 'badge-warning',
        ACCEPTED: 'badge-warning',
        APPROVED: 'badge-success',
        CANCEL_APPROVED: 'badge-success',
        CANCELLED: 'badge-danger',
        REJECTED: 'badge-danger',
        'WAITING FOR REQUEST CLARIFICATION': 'badge-secondary',
        TP_ACKNOWLEDGED: 'badge-primary'
      }
    },
    /*{
      name: 'indentRequestDetails?.indentRequestStatus',
      displayName: 'wholesale.indentrequest.status',
      displayFcn: (item: any) => item.indentRequestDetails.indentRequestStatus,
      type: 'objstring'
      // badgeColors: {
      //   SUBMITTED: 'badge-success',
      //   DRAFT: 'badge-warning'
      // }
    },*/
  ];
  public static csdpermit = [
    {
      name: 'id',
      displayName: 'S.No.',
      type: 'number'
    },

    {
      name: 'indent_number',
      displayName: 'CSD Permit Number',
      type: 'string'
    },
    {
      name: 'created_date',
      displayName: 'CSD Permit Date',
      type: 'date'
    },
    {
      name: 'total_cases',
      displayName: 'Total No. of Cases/Monocartons',
      type: 'string'
    },
    {
      name: 'total_bottles',
      displayName: 'Total No. of Bottles',
      type: 'string'
    },
    {
      name: 'entity_type_code',
      displayName: 'Unit Type',
      type: 'string',
      align: 'center'
    },
    {
      name: 'to_entity_code',
      displayName: 'Unit Name',
      type: 'string',
      align: 'center'
    },
    {
      name: 'currently_with',
      displayName: 'Currently With',
      type: 'string',
      align: 'center'
    },
    {
      name: 'status',
      displayName: 'Status',
      type: 'options',
      badgeColors: {
        SUBMITTED: 'badge-info',
        DRAFT: 'badge-warning',
        ACCEPTED: 'badge-warning',
        APPROVED: 'badge-success',
        CANCELLED: 'badge-danger',
        REJECTED: 'badge-danger',
        'WAITING FOR REQUEST CLARIFICATION': 'badge-secondary',
        TP_ACKNOWLEDGED: 'badge-primary'
      }
    },
  ];


  public static CSDPermitCancellationApprovalList = [
    {
      name: 'id',
      displayName: 'S.No.',
      type: 'number'
    },

    {
      name: 'indent_number',
      displayName: 'CSD Permit Number',
      type: 'string'
    },
    {
      name: 'total_cases',
      displayName: 'Total No. of Cases/Monocartons',
      type: 'string'
    },
    {
      name: 'total_bottles',
      displayName: 'Total No. of Bottles',
      type: 'string'
    },
    {
      name: 'entity_type_code',
      displayName: 'Unit Type',
      type: 'string',
      align: 'center'
    },
    {
      name: 'to_entity_code',
      displayName: 'Unit Name',
      type: 'string',
      align: 'center'
    },
    {
      name: 'status',
      displayName: 'Status',
      type: 'options',
      badgeColors: {
        SUBMITTED: 'badge-info',
        DRAFT: 'badge-warning',
        ACCEPTED: 'badge-warning',
        APPROVED: 'badge-success',
        CANCELLED: 'badge-danger',
        REJECTED: 'badge-danger',
        'WAITING FOR REQUEST CLARIFICATION': 'badge-secondary',
        TP_ACKNOWLEDGED: 'badge-primary'
      }
    }
  ];
  status: string;

  public static subImportListColumns = [
    {
      name: 'id',
      displayName: 'S.No.',
      type: 'number'
    },
    {
      name: 'indent_number',
      displayName: 'Import Permit Number',
      type: 'string'
    },
    {
      name: 'parent_indent_number',
      displayName: 'Parent Import Permit Number',
      type: 'string'
    },
    {
      name: 'created_date',
      displayName: 'Permit Date',
      displayFcn: (item: any) => moment(item.created_date).format("DD-MM-YYYY"),
      type: 'date'
    },
    {
      name: 'total_cases',
      displayName: 'Total No. of Cases/Monocartons',
      type: 'string'
    },
    {
      name: 'total_bottles',
      displayName: 'Total No. of Bottles',
      type: 'string'
    },
    {
      name: 'from_entity_type_code',
      displayName: 'Unit Type',
      type: 'string',
      align: 'center'
    },
    {
      name: 'from_unit_name',
      displayName: 'Unit Name',
      type: 'string',
      align: 'center'
    },
    {
      name: 'status',
      displayName: 'Status',
      type: 'options',
      badgeColors: {
        SUBMITTED: 'badge-info',
        DRAFT: 'badge-warning',
        ACCEPTED: 'badge-warning',
        CANCEL_APPROVED: 'badge-success',
        CANCELLED: 'badge-danger',
        REJECTED: 'badge-danger',
        'WAITING FOR REQUEST CLARIFICATION': 'badge-secondary',
        TP_ACKNOWLEDGED: 'badge-primary'
      }
    },
  ];

  
}
