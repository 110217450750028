import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "@appEnv/environment";
import { Observable } from "rxjs";
import { apiUrls } from '@appEnv/apiurls';
@Injectable({
    providedIn: "root"
})
export class PassbookserviceService {
    constructor(private http: HttpClient) { }
    baseURL = environment.parentUnit;
    devisionUrl = environment.devisionUrl;
    licenseURL = environment.licneseBaseUrl;
    url = environment.devisionUrl;
    domainUrl = environment.domainUrl;
 

      //new api for passbook

      tankDismantleSearch(data): Observable<any> {
        const url = this.baseURL + 'passBook/lazySearch';
        return this.http.post(url, data);
    }

      addTankDismantleDetails(data): Observable<any> {
        const url = this.baseURL + 'passBook';
        return this.http.post(url, data);
    }
      getById(id): Observable<any> {
        const url = this.baseURL + 'passBook/' + id;
        return this.http.get(url);
    }
    downloadPayment(appnumber, liceanseNumber, feeType): any {
      const url = this.baseURL + 'reportReceipt/downloadPaymentReceipt' + '?applicationNumber=' + appnumber + '&licenseNumber=' + liceanseNumber + '&feeType=' + feeType + '&receiptType=PASS_BOOK';
      return this.http.get(url, { responseType: 'blob' });
    }
    downloadenavalue(payload) {
      const url = this.baseURL + 'reportReceipt/downloadReceipt'
      return this.http.get(url, { responseType: 'blob', params: payload });
    }
    licenceAdd(payload): Observable<any> {
      const url = this.domainUrl + 'payandrecon/1.0/license/multipleadd';
      return this.http.post(url, payload);
    }
    saveupdatepaymentdetails(payload): Observable<any> {
      const url = this.baseURL + 'passBook/saveUpdatePaymentDetails';
      return this.http.post(url, payload);
    }
    findpaymentdetails(applicationno, feetype): Observable<any> {
      let url = this.baseURL + 'passBook/findPaymentDetails?applicationNumber=' + applicationno + '&feeType=' + feetype;
      return this.http.get(url);
    }
      getsugarmill(entityType): any {
        const url = this.licenseURL + 'license/category/' + entityType + '?optionalCategory=SUGAR_MILL';
        return this.http.get(url).toPromise();
      }

         getapplicationnumbers(data): any {
            const url = this.baseURL + 'generateApplicationNumber?registrationType=' + data.registrationType + '&licenseNumber=' + data.licenseNumber; 
        return this.http.post(url, data).toPromise();
      }

      downloadData(payload) {
        const url = this.baseURL + 'reportReceipt/downloadPassBookReceipt';
         return this.http.get(url, { responseType: 'blob', params: payload });
      }

     
    
}