import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '@appEnv/environment';
import { apiUrls } from '@appEnv/apiurls'
@Injectable({
    providedIn: 'root'
})
export class TankMixingService {
    baseURL = environment.sugarmillBaseURL;
    baseURL1 = environment.scmproduction;
    constructor(private http: HttpClient) { }

    lazySearch(postdata): Observable<any> {
        const url = this.baseURL1 + apiUrls.gettankmixing;
        return this.http.post(url, postdata);
    }

    gettankdetails(id): Observable<any> {
        const url = this.baseURL1 + 'tankMixing/getTankDetails?tankId=' + id;;
        return this.http.get(url, id);
    }
    addTankMixing(postdata): Observable<any> {
        const url = this.baseURL1 + 'tankMixing'
        return this.http.post(url, postdata);
    }
    getTankMixById(id): Observable<any> {
        const url = this.baseURL1 + 'tankMixing/' + id;
        return this.http.get(url);
    }
    // getStatusCount(entityType,isCurrentOfficerAllRecords,isAllOfficerRecords,isOtherOfficerAllRecords): Observable<any> {
    //     const url = this.baseURL1 + 'tankMixing/getStatusCount?entityType=' + entityType 
    //     +'&isCurrentOfficerAllRecords=' +isCurrentOfficerAllRecords +'&isAllOfficerRecords=' + isAllOfficerRecords 
    //     +"&isOtherOfficerAllRecords=" +isOtherOfficerAllRecords;
    //     // const url = 'http://localhost:3501/scmProduction/tankMixing/getStatusCount?entityType=' + entityType 
    //     // +'&isCurrentOfficerAllRecords=' + isCurrentOfficerAllRecords + '&isAllOfficerRecords=' + isAllOfficerRecords 
    //     // +'&isOtherOfficerAllRecords=' +isOtherOfficerAllRecords;
    //     return this.http.get(url);
    // }
    getSugarMillCardCount(payload): Observable<any> {
        const url = this.baseURL1 + 'sugarMill/getStatusCount'
        return this.http.post(url,payload);
      }
    downloadmixing(payload){
        const url = this.baseURL1 + 'reportReceipt/downloadReceipt'
        //  const url = 'http://localhost:3501/scmProduction/reportReceipt/downloadReceipt';
        return this.http.get(url,{ responseType: 'blob', params:payload });
    }

}