export class BoxingConfig {
    public static Pendingcolumns = [
        {
            name: 'id',
            displayName: 'brewery.boxing.boxingid',
            displayFcn: (item: any) => item.id,
            type: 'objstring'
        },
        {
            name: 'bottling_plan_id',
            displayName: 'brewery.boxing.bottlingplanid',
            displayFcn: (item: any) => item.bottling_plan_id,
            type: 'objstring'
        },
        {
            name: 'brand_value',
            displayName: 'brewery.boxing.brandname',
            displayFcn: (item: any) => item.brand_value,
            type: 'objstring'
        },
        {
            name: 'created_date',
            displayName: 'Plan Date',
            displayFcn: (item: any) => item.created_date,
            type: 'date'
        },
        {
            name: 'date_of_packaging',
            displayName: 'brewery.boxing.dateofpackaging',
            displayFcn: (item: any) => item.date_of_packaging,
            type: 'date'
        },
        {
            name: 'code_type_value',
            displayName: 'brewery.boxing.codetype',
            displayFcn: (item: any) => item.code_type_value,
            type: 'objstring'
        },
        {
            name: 'no_of_cases',
            displayName: 'brewery.boxing.noofcases',
            displayFcn: (item: any) => item.no_of_cases,
            type: 'objstring',
            align:'center'
        },
        // {
        //     name: 'status',
        //     displayName: 'Status',
        //     displayFcn: (item: any) => item.boxing_status == null ? "PENDING" : item.boxing_status,
        //     type: 'objstring'
        // },
        {
            name: 'status1',
            displayName:'brewery.boxing.status',
            displayFcn: (item: any) =>  item.status1,
            type: 'options',
            badgeColors: {
                PENDING: 'badge-success'
            }
        },
    ];
    public static InProgresscolumns = [
        {
            name: 'id',
            displayName: 'brewery.boxing.boxingid',
            displayFcn: (item: any) => item.id,
            type: 'objstring'
        },
        {
            name: 'bottling_plan_id',
            displayName: 'brewery.boxing.bottlingplanid',
            displayFcn: (item: any) => item.bottling_plan_id,
            type: 'objstring'
        },
        {
            name: 'brand_value',
            displayName: 'brewery.boxing.brandname',
            displayFcn: (item: any) => item.brand_value,
            type: 'objstring'
        },
        {
            name: 'created_date',
            displayName: 'Plan Date',
            displayFcn: (item: any) => item.created_date,
            type: 'date'
        },
        {
            name: 'date_of_packaging',
            displayName: 'brewery.boxing.dateofpackaging',
            displayFcn: (item: any) => item.date_of_packaging,
            type: 'date'
        },
        {
            name: 'code_type_value',
            displayName: 'brewery.boxing.codetype',
            displayFcn: (item: any) => item.code_type_value,
            type: 'objstring'
        },
        {
            name: 'no_of_cases',
            displayName: 'brewery.boxing.noofcases',
            displayFcn: (item: any) => item.no_of_cases,
            type: 'objstring',
            align:'center'
        },
        {
            name: 'status1',
            displayName:'brewery.boxing.status',
            displayFcn: (item: any) =>  item.status1,
            type: 'options',
            badgeColors: {
                'IN-PROGRESS': 'badge-success'
            }
        },
    ];
    public static Completedcolumns = [
        {
            name: 'id',
            displayName: 'brewery.boxing.boxingid',
            displayFcn: (item: any) => item.id,
            type: 'objstring'
        },
        {
            name: 'bottling_plan_id',
            displayName: 'brewery.boxing.bottlingplanid',
            displayFcn: (item: any) => item.bottling_plan_id,
            type: 'objstring'
        },
        {
            name: 'brand_value',
            displayName: 'brewery.boxing.brandname',
            displayFcn: (item: any) => item.brand_value,
            type: 'objstring'
        },
        {
            name: 'created_date',
            displayName: 'Plan Date',
            displayFcn: (item: any) => item.created_date,
            type: 'date'
        },
        {
            name: 'date_of_packaging',
            displayName: 'brewery.boxing.dateofpackaging',
            displayFcn: (item: any) => item.date_of_packaging,
            type: 'date'
        },
        {
            name: 'code_type_value',
            displayName: 'brewery.boxing.codetype',
            displayFcn: (item: any) => item.code_type_value,
            type: 'objstring'
        },
        {
            name: 'no_of_cases',
            displayName: 'brewery.boxing.noofcases',
            displayFcn: (item: any) => item.no_of_cases,
            type: 'objstring',
            align:'center'
        },
        // {
        //     name: 'status',
        //     displayName: 'Status',
        //     displayFcn: (item: any) => item.boxing_status == null ? "PENDING" : item.boxing_status,
        //     type: 'objstring'
        // },
        {
            name: 'status1',
            displayName:'brewery.boxing.status',
            displayFcn: (item: any) =>  item.status1,
            type: 'options',
            badgeColors: {
                COMPLETED: 'badge-success'
            }
        },
    ];
    public static columnsReport = [
        {
            name: 'id',
            displayName: 'S No',
            type: 'number'
        },
        {
            name: 'dispatchDatee',
            displayName: 'Disaptch Date',
            type: 'date'
        },
        {
            name: 'transportPassNoe',
            displayName: 'Transport Pass No',
            type: 'string'
        },
        {
            name: 'unitNamee',
            displayName: 'Unit Name',
            type: 'string'
        },
        {
            name: 'totScannedCasese',
            displayName: 'Cases Dispatched',
            type: 'string'
        },
        {
            name: 'totScannedBottlese',
            displayName: 'Bottles Dispatched',
            type: 'string'
        },

    ];
    public static ApprovalColumns = [
        {
            name: 'id',
            displayName: 'brewery.boxing.boxingid',
            displayFcn: (item: any) => item.id,
            type: 'objstring'
        },
        {
            name: 'bottling_plan_id',
            displayName: 'brewery.boxing.bottlingplanid',
            displayFcn: (item: any) => item.bottling_plan_id,
            type: 'objstring'
        },
        {
            name: 'brand_value',
            displayName: 'brewery.boxing.brandname',
            displayFcn: (item: any) => item.brand_value,
            type: 'objstring'
        },
        // {
        //     name: 'date_of_packaging',
        //     displayName: 'brewery.boxing.dateofpackaging',
        //     displayFcn: (item: any) => item.date_of_packaging,
        //     type: 'date'
        // },
        // {
        //     name: 'code_type_value',
        //     displayName: 'brewery.boxing.codetype',
        //     displayFcn: (item: any) => item.code_type_value,
        //     type: 'objstring'
        // },
        {
            name: 'no_of_cases',
            displayName: 'brewery.boxing.noofcases',
            displayFcn: (item: any) => item.no_of_cases,
            type: 'objstring',
            align:'center'
        },
        {
            name: 'no_of_bottles',
            displayName: 'brewery.boxing.noofbottles',
            displayFcn: (item: any) => item.no_of_bottles,
            type: 'objstring',
            align:'center'
        },
        // {
        //     name: 'status',
        //     displayName: 'Status',
        //     displayFcn: (item: any) => item.boxing_status == null ? "PENDING" : item.boxing_status,
        //     type: 'objstring'
        // },
        // {
        //     name: 'status1',
        //     displayName:'brewery.boxing.status',
        //     displayFcn: (item: any) =>  item.status1,
        //     type: 'options',
        //     badgeColors: {
        //         PENDING: 'badge-success'
        //     }
        // },
    ];
}