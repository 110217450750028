export class Internalauditconfig {

    // public static titleSection = {
    //   list: {
    //       title: 'master.district.list',
    //       button: {
    //           text: 'master.district.adddistrict',
    //           url: '/location/district/add'
    //       }
    //   },
    //   add: {
    //       title: 'master.district.list'
    //   },
    //   edit: {
    //       title: 'master.district.list'
    //   },
    //   view: {
    //       title: 'master.district.list'
    //   }
    // };

    // public static breadcrumbs = [
    //   {
    //     text: 'language.chemicalLab',
    //     url: '/returnfiling/return-filing-wholesale-beer/list',
    //     hasHref: true
    //   },
    //   {
    //     text: 'language.samplerequest',
    //     url: '/returnfiling/return-filing-wholesale-beer/list',
    //     hasHref: true
    //   } 
    // ];

    public static columns = [
        {
            name: 'id',
            displayName: 'language.s.no',
            type: 'number'
        },
        {
            name: "applicationNumber",
            displayName: 'language.auditid',
            type: "string",
        },
        {
            name: 'officeType',
            displayName: 'language.exciseoffice',
            type: "string",
        },
        {
            name: 'districtTypeValue', // "districtType",
            displayName: "language.districtname",
            type: "string",
        },
        {
            name: "stageDesc",
            displayName: 'language.currentlywith',
            displayFcn: (item: any) => (item.status == 'APPROVED' || item.status == 'DRAFT' || item.status == 'SUBMITTED') ? '-' : item.stageDesc,
            type: 'objstring'
        },
        {
            name: 'statusDesc',
            displayName: 'language.status',
            type: 'options',
            badgeColors: {
                'Draft': 'badge-primary',
                'In Progress': 'badge-secondary',
                'Approved': 'badge-success',
                'Submitted': 'badge-info',
                'Rejected': 'badge-danger',
                'Clarification': 'badge-warning'
            }
        },
    ];
}